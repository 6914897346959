import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
  OnChanges
} from '@angular/core';
import { RolesService } from '../services/roles/roles.service';
import { UserType } from '../user-roles';

@Directive({
  selector: '[canNotSee]'
})
export class CanNotSeeDirective implements OnChanges {
  hasView: boolean = false;
  hasAccess: boolean;

  private _canNotSee: UserType | UserType[];
  @Input() set canNotSee(role: UserType | UserType[]) {
    //this.hasAccess = hasAccess;
    let hasRole = this.rolesService.hasHighlevelAccess(role);

    if (hasRole && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    } else if (!hasRole && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    }
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private rolesService: RolesService
  ) {}
  ngOnChanges() {}
}
