import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Defaults } from 'src/app/defaults';
import Chart from 'chart.js/auto';
import { ChartConfiguration } from 'chart.js';
import 'chartjs-adapter-moment';
import { HttpInterceptorService } from 'src/app/services/http-interceptor/http-interceptor.service';
import * as moment from 'moment';
import { DecimalPipe, getCurrencySymbol } from '@angular/common';
import { UserType } from 'src/app/user-roles';
import { TableOptions } from 'src/app/classes/table-options';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GraphType } from './graph-type';
import { AmChart } from '@amcharts/amcharts3-angular';
import { CustomCurrencyPipe } from 'src/app/pipes/custom-currency/custom-currency.pipe';
import { TranslateService } from '@ngx-translate/core';
import { UnitTypeName, UnitTypeNameShort } from 'src/app/types/UnitType';
import { AppEnv } from 'src/app/AppEnv';
import { ActivatedRoute } from '@angular/router';
import { combineLatestWith, forkJoin, map } from 'rxjs';
import { RoamingPlatform } from 'src/app/lib/roaming';
import { RoamingPlatformFacade } from 'src/app/lib/state/roaming-platform';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { OrderItemComponent } from './order-item/order-item.component';

@Component({
  selector: 'sc-single-session',
  templateUrl: './single-session.component.html',
  styleUrls: ['./single-session.component.scss']
})
export class SingleSessionComponent implements OnInit {
  @ViewChild('sessionGraph') sessionGraph: ElementRef;
  chart: AmChart = null;
  @ViewChild('pricingDetailsChart') pricingDetailsChart: ElementRef;
  session: any;
  order: any;
  showLogs = false;
  hideLogs = false;
  sessionID: number;
  userType = UserType;
  tableOptions = new TableOptions(10);
  refreshing = false;
  loading = false;
  loadingDatas = true;
  actions: any[] = [];
  selectedIndex = 0;
  defaultSessionGraphUnit = GraphType.KW;
  GraphType = GraphType;
  graphData: any = {};
  isDifferentChartType = false;
  sessionOrderItems = [];
  selectedOrderItem: any = null;
  currency = 'NOK';
  unitTypeName = UnitTypeName;
  unitTypeNameShort = UnitTypeNameShort;
  showPaymentStatus = false;
  paymentStatus = '';
  showPricingDetailsChart = true;
  stationID: number;
  roamingPlatformByID: { [ID: number]: RoamingPlatform } = {};
  featureLocalOrStaging = new AppEnv().envStagingOrLocal();
  chargingBoxModel: any;

  constructor(
    private defaults: Defaults,
    private _http: HttpInterceptorService,
    private decimalPipe: DecimalPipe,
    private snackBar: MatSnackBar,
    private scCurrency: CustomCurrencyPipe,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private roamingplatFormFacade: RoamingPlatformFacade,
    private bottomSheet: MatBottomSheet
  ) {}
  ngOnInit() {
    this.route.params
      .pipe(combineLatestWith(this.route.queryParams))
      .pipe(
        map(([params, queryParams]) => ({
          sessionID: params.sessionID,
          logs: queryParams.logs === 'true'
        }))
      )
      .subscribe((allParams) => {
        this.sessionID = Number(allParams.sessionID);
        this.showLogs = allParams.logs;
        this.fetchSessionAndOrder();
        this.fetchRoamingPlatforms();
      });
  }

  fetchRoamingPlatforms() {
    // Check roaming platforms in the state before fetching
    this.roamingplatFormFacade.roamingPlatforms$.subscribe({
      next: (res) => {
        if (res) {
          if (res.length) {
            res.forEach((platform) => {
              this.roamingPlatformByID[platform.Id] = platform;
            });
          } else {
            this.roamingplatFormFacade.loadRoamingPlatforms();
          }
        }
      },
      error: (err) => {}
    });
  }

  fetchSessionAndOrder(refreshing = false) {
    if (!this.sessionID) return;
    this.refreshing = refreshing;
    forkJoin([
      this._http.get(`ServiceSessions/${this.sessionID}`),
      this._http.get(`ChargingHistory/BySession/${this.sessionID}`)
    ]).subscribe({
      next: ([session, order]) => {
        this.fetchChargerBoxModel(session.ChargingBoxID);
        this.session = session;
        this.getSessionDatas(refreshing);
        this.getLogs();
        this.hideLogs = !order.CanClickOnPoint;
        this.currency = order.Currency;
        this.showPaymentStatus = true;
        this.paymentStatus = this.getOrderPaymentStatus(order);
        this.stationID = order?.StationID;
        this.order = order;
        if (this.showLogs) {
          this.selectedIndex = 1;
        }
      },
      error: (err) => {
        this.snackBar.open(this.translate.instant('SOMETHING_WENT_WRONG'), this.translate.instant('CLOSE'));
      }
    });
  }

  fetchChargerBoxModel(chargingBoxID: number) {
    this._http.get(`ChargingBoxes/GetBox/${chargingBoxID}`).subscribe({
      next: (box) => {
        if (box?.FK_ChargingBoxModelID) {
          this._http.get(`ChargingBoxModels/${box.FK_ChargingBoxModelID}`).subscribe((model) => {
            this.chargingBoxModel = model;
          });
        }
      },
      error: (err) => {
        this.snackBar.open(this.translate.instant('SOMETHING_WENT_WRONG'), this.translate.instant('CLOSE'));
      }
    });
  }

  getLogs(refresh?) {
    this.refreshing = refresh;
    let limit = this.tableOptions.limit;
    let startIndex = this.tableOptions.limit * this.tableOptions.offset;
    if (!this.session) return;
    this._http
      .get(`ServiceSessions/GetLogsSession/${this.session?.PK_ServiceSessionID}/${limit}/${startIndex}`)
      .subscribe({
        next: (res) => {
          this.tableOptions.total = res.TotalLogs;
          this.refreshing = false;
          this.loading = false;
          this.actions = res.Items;
        },
        error: (err) => {
          this.loading = false;
          this.refreshing = false;
        }
      });
  }

  graphChange(ev) {
    this.selectedOrderItem = null;
    this.showPricingDetailsChart = true;
    if (ev.value === GraphType.DETAILS) {
      this.drawPricingDetailsChart();
    } else {
      this.drawGraph(ev.value);
    }
    this.defaultSessionGraphUnit = ev.value;
  }

  onPage(e) {
    this.tableOptions = Object.assign(this.tableOptions, e);
    this.loading = true;
    this.getLogs();
  }

  getSessionDatas(refreshing = false) {
    this.loadingDatas = refreshing;
    this._http.get('LoadBalancing/GetDatasSession/' + this.session.PK_ServiceSessionID).subscribe({
      next: (res) => {
        this.loadingDatas = false;
        if (!res.datas.length) return (this.graphData.noData = true);

        Object.keys(GraphType).forEach((key) => {
          const points = [];
          const labels = [];
          const L1 = [];
          const L2 = [];
          const L3 = [];

          res.datas.forEach((obj) => {
            if (obj[key] !== null) {
              if (obj[key] < 0) obj[key] = 0;
              points.push(obj[key]);
              labels.push(moment(obj.Date));
              if (['KW', 'Amps'].includes(key)) {
                L1.push(obj[`${key}_L1`]);
                L2.push(obj[`${key}_L2`]);
                L3.push(obj[`${key}_L3`]);
              }
            }
          });
          this.graphData[key] = { points, labels, L1, L2, L3 };
        });

        this.defaultSessionGraphUnit = this.getDefaultUnit(this.graphData);
        this.drawGraph(this.defaultSessionGraphUnit);
      },
      error: (err) => {
        this.snackBar.open(
          this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
            errorName: err.errorName
          }),
          this.translate.instant('CLOSE')
        );
        this.loadingDatas = false;
      }
    });

    this._http.get(`ServiceSessions/Orders/${this.session.PK_ServiceSessionID}`).subscribe({
      next: (orderSession) => {
        this.sessionOrderItems = orderSession.datas || [];
      },
      error: (err) => {}
    });
  }

  drawGraph(type: GraphType) {
    let param = 'kW';
    switch (type) {
      case GraphType.KW:
        param = 'KW';
        break;
      case GraphType.Amps:
        param = 'Amps';
        break;
      case GraphType.KWH:
        param = 'KWH';
        break;
      case GraphType.SoC:
        param = 'SoC';
        break;
      default:
        param = 'KW';
    }

    const allPhases = [...this.graphData[param].L1, ...this.graphData[param].L2, ...this.graphData[param].L3];
    const showThreePhase = allPhases.some((item) => item !== null);
    let datasets: any;
    let chartLegend: any;

    if (showThreePhase) {
      chartLegend = { position: 'top', display: true };
      datasets = [
        {
          label: 'L1',
          data: this.graphData[param].L1,
          fill: false,
          borderColor: this.defaults.colors.primary,
          backgroundColor: this.defaults.colors.primary,
          tension: 0.2
        },
        {
          label: 'L2',
          data: this.graphData[param].L2,
          fill: false,
          borderColor: this.defaults.colors.warn,
          backgroundColor: this.defaults.colors.warn,
          tension: 0.2
        },
        {
          label: 'L3',
          data: this.graphData[param].L3,
          fill: false,
          borderColor: this.defaults.colors.danger,
          backgroundColor: this.defaults.colors.danger,
          tension: 0.2
        }
      ];
    } else {
      chartLegend = { display: false };
      datasets = [
        {
          label: type,
          data: this.graphData[param].points,
          fill: false,
          borderColor: this.defaults.colors.primary,
          backgroundColor: this.defaults.colors.primary,
          tension: 0.2
        }
      ];
    }

    let config: ChartConfiguration = {
      type: 'line',
      data: {
        labels: this.graphData[param].labels.map((date) => date.format('LT')),
        datasets: datasets
      },
      options: {
        responsive: true,
        layout: {
          padding: {
            left: 16,
            right: 16
          }
        },
        plugins: {
          legend: chartLegend,
          tooltip: {
            mode: 'nearest',
            intersect: false,

            callbacks: {
              title: (item) => {
                return `${this.decimalPipe.transform(Number(item[0].raw), '1.0-2')} ${item[0].dataset.label}`;
              },
              label: (item) => {
                const date = this.graphData[param].labels[item.dataIndex];
                return `${date.format('Do MMM')} ${item.label}`;
              }
            }
          }
        },
        elements: {
          point: {
            radius: 1,
            hitRadius: 20,
            borderWidth: 1
          }
        },
        scales: {
          y: {
            beginAtZero: true,
            grid: {
              display: false,
              drawBorder: false
            }
          },
          x: {
            grid: {
              display: false,
              drawBorder: false
            }
          }
        }
      }
    };
    if (this.chart && !this.isDifferentChartType) {
      //Must update
      this.chart.data.datasets = datasets;
      this.chart.data.labels = this.graphData[param].labels.map((date) => date.format('LT'));
      this.chart.options.plugins.legend = chartLegend;
      this.chart.update();
    } else {
      //Must draw
      if (this.chart) this.chart.destroy();
      this.chart = new Chart(this.sessionGraph.nativeElement.getContext('2d'), config);
      this.isDifferentChartType = false;
    }
  }

  displayName(session) {
    if (!session) return;
    let s: string = null;

    if (session.CustomerFullName && session.CustomerFullName.trim() && session.CustomerFullName !== 'sms  sms') {
      return session.CustomerFullName;
    } else if (session.CustomerEmail) {
      return session.CustomerEmail;
    } else if (session.CustomerIdentifier) {
      return session.CustomerIdentifier;
    } else {
      return;
    }
  }

  getDefaultUnit(graphData: any) {
    const isKwZero = graphData.KW.points.every((kw) => kw === 0);
    if (isKwZero) {
      const isAmprZero = graphData.Amps.points.every((ampr) => ampr === 0);
      return isAmprZero ? GraphType.KWH : GraphType.Amps;
    }
    return GraphType.KW;
  }

  drawPricingDetailsChart = () => {
    this.isDifferentChartType = true;
    if (this.sessionOrderItems.length === 1 && this.sessionOrderItems[0].TotalPrice <= 0) {
      this.showPricingDetailsChart = false;
      this.selectedOrderItem = this.sessionOrderItems[0];
      if (this.chart) this.chart.destroy();
      return;
    }

    const orderLengthInMinutes = [];
    let sumOfMinutes = 0;
    const lab = this.sessionOrderItems.map((item) => {
      const itemStartTime = moment(item.TimeStarted);
      const itemEndTime = moment(item.TimeFinished);
      const duration = moment.duration(itemEndTime.diff(itemStartTime));
      const minutes = duration.asMinutes();
      sumOfMinutes += minutes;
      orderLengthInMinutes.push(minutes);
      return `${itemStartTime.format('LT')}-${itemEndTime.format('LT')}`;
    });

    const barColor = this.sessionOrderItems.map(() => this.defaults.colors.primary);
    const datasets: any[] = [
      {
        label: 'kWh',
        yAxisID: 'kWh',
        data: this.sessionOrderItems.map((item) => item.ChargingUnitsConsumed),
        fill: false,
        borderColor: this.defaults.colors.primary,
        backgroundColor: this.defaults.colors.primary,
        tension: 0.2,
        type: 'line'
      },
      {
        label: this.translate.instant('PRICE'),
        yAxisID: 'price',
        data: this.sessionOrderItems.map((item) => item.TotalPrice),
        orderLengthInMinutes: orderLengthInMinutes,
        sumOfMinutes: sumOfMinutes,
        borderColor: barColor,
        backgroundColor: barColor,
        borderWidth: 0.7,
        borderRadius: 20,
        categoryPercentage: this.sessionOrderItems.length > 1 ? 1 : 0.5,
        barPercentage: this.sessionOrderItems.length > 1 ? 1 : 0.5
      }
    ];

    const config: ChartConfiguration = {
      type: 'bar',
      data: {
        labels: lab,
        datasets: datasets
      },
      options: {
        layout: {
          padding: {
            top: 24,
            left: 16,
            right: 16
          }
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            mode: 'index',
            intersect: false,
            callbacks: {
              title: (items) => {
                const el = this.sessionOrderItems[items[0].dataIndex] || null;
                return [
                  `${this.translate.instant('FROM')} ${this.formatTime(
                    el.TimeStarted
                  )} ${this.translate.instant('TO')} ${this.formatTime(el.TimeFinished)}`
                ];
              },
              label: (item) => {
                if (item.dataset.yAxisID === 'kWh') {
                  return `${this.translate.instant('KWH_CHARGED')}: ${item.raw}`;
                }
                return [
                  `${this.translate.instant('TOTAL_PRICE')}:  ${this.scCurrency.transform(
                    item.raw,
                    this.currency,
                    'symbol-narrow'
                  )}`,
                  this.translate.instant('SINGLE_SESSION_TEXT_9')
                ];
              }
            }
          }
        },
        scales: {
          kWh: {
            position: 'left',
            title: {
              display: true,
              text: this.translate.instant('KWH_CHARGED')
            },
            grid: {
              display: false,
              color: this.defaults.lightenColor(this.defaults.colors.primary, 30),
              borderDash: [4, 4]
            },
            ticks: {
              display: false,
              color: this.defaults.colors.primary
            }
          },
          price: {
            position: 'right',
            title: {
              display: true,
              text: `${this.translate.instant('TOTAL_PRICE')} (${getCurrencySymbol(this.currency, 'narrow')})`
            },
            grid: {
              display: false,
              borderDash: [4, 4],
              color: this.defaults.lightenColor(this.defaults.colors.secondary, 20)
            },
            ticks: {
              display: false,
              color: this.defaults.colors.primary
            }
          },
          x: {
            title: {
              display: false
            },
            ticks: {
              display: true,
              maxRotation: 90,
              minRotation: 55
            },
            grid: {
              display: false
            }
          }
        },
        onClick: (event, elements) => {
          if (elements.length) {
            this.selectedOrderItem = this.sessionOrderItems[elements[0].index];
            this.bottomSheet.open(OrderItemComponent, {
              data: {
                orderItem: this.selectedOrderItem,
                currency: this.currency,
                stationID: this.stationID
              }
            });
            this.updateBarColor(elements[0].index);
          }
        },
        onHover: (event: any, elements) => {
          event.native.target.style.cursor = elements[0] ? 'pointer' : 'default';
        }
      },
      plugins:
        this.sessionOrderItems.length > 1
          ? [
              {
                id: 'dynamicWidth',
                beforeDatasetDraw: (chart: any) => {
                  const {
                    ctx,
                    scales: { x, y },
                    chartArea: { width, left }
                  } = chart;
                  const lineDatasetIndex = 0;
                  const barDatasetIndex = 1;
                  const orderLengthInMinutes = chart.data.datasets[barDatasetIndex].orderLengthInMinutes;
                  const totalMiutes = chart.data.datasets[barDatasetIndex].sumOfMinutes;
                  // const labels = chart.data.labels;
                  const gapBetweenBars = 5;
                  const chartAreaWidth = width - 70;
                  chart.getDatasetMeta(barDatasetIndex).data.forEach((datapoint, index) => {
                    const orderMinutes = orderLengthInMinutes[index];

                    //calculate bar width
                    const widthRatioInPercent = orderMinutes / (totalMiutes / 100);
                    const barWidth = widthRatioInPercent * (chartAreaWidth / 100);
                    datapoint.width = barWidth;

                    //calculate bar x-axis position.
                    if (index > 0) {
                      const previousBar = chart.getDatasetMeta(barDatasetIndex).data[index - 1];
                      datapoint.x = previousBar.x + previousBar.width / 2 + barWidth / 2 + gapBetweenBars;
                    } else {
                      datapoint.x = left + barWidth / 2 + gapBetweenBars;
                    }
                  });

                  // //Ajust line position
                  const barDataset = chart.getDatasetMeta(barDatasetIndex).data;
                  chart.getDatasetMeta(lineDatasetIndex).data.forEach((datapoint, index) => {
                    datapoint.x = barDataset[index].x;
                    // datapoint.cp1x = barDataset[index].x;
                    // datapoint.cp2x = barDataset[index].x;
                  });

                  // //Ajust xAxis position
                  x._labelItems.forEach((label, index) => {
                    label.translation[0] = barDataset[index].x;
                  });
                }
              }
            ]
          : []
    };
    if (this.chart) this.chart.destroy();
    this.chart = new Chart(this.sessionGraph.nativeElement.getContext('2d'), config);
  };

  updateBarColor = (index?: number) => {
    let newColors = [];
    const barDatasetIndex = 1;
    if (this.selectedOrderItem) {
      newColors = this.chart.data.datasets[barDatasetIndex].backgroundColor.slice().map(() => '#eaebe6');
      newColors[index] = this.defaults.lightenColor(this.defaults.colors.primary, 10);
    } else {
      newColors = this.chart.data.datasets[barDatasetIndex].backgroundColor
        .slice()
        .map(() => this.defaults.lightenColor(this.defaults.colors.primary, 10));
    }
    this.chart.data.datasets[barDatasetIndex].backgroundColor = newColors;
    this.chart.data.datasets[barDatasetIndex].borderColor = newColors;
    this.chart.update('resize');
    if (!index) {
      this.chart.render();
    }
  };

  formatTime = (time: string) => {
    return moment(time).format('LT');
  };

  goBack() {
    window.history.back();
  }

  getOrderPaymentStatus(order) {
    if (order.InvoiceID) {
      return order.IsInvoicedPaid ? this.translate.instant('PAID') : this.translate.instant('NOT_PAID');
    } else {
      return order.TotalPrice === 0 ? '-' : this.translate.instant('NOT_INVOICED');
    }
  }
}
