import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from 'src/app/modules/shared.module';
import { LimitationPopoverComponent } from './limitation-popover.component';

@NgModule({
  imports: [CommonModule, MatListModule, NgbModule, SharedModule],
  declarations: [LimitationPopoverComponent],
  exports: [LimitationPopoverComponent]
})
export class LimitationPopoverModule {}
