import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { JsonViewerComponent } from './json-viewer.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    PipesModule,
    NgxJsonViewerModule
  ],
  declarations: [JsonViewerComponent]
})
export class JsonViewerModule {}
