<span
  class="hover-text"
  triggers="mouseenter:mouseleave"
  placement="auto"
  container="body"
  [ngbPopover]="powerLimitation">
  {{ value }}
</span>

<ng-template #powerLimitation>
  <mat-list class="how-to" *ngIf="!customMessage">
    <mat-list-item class="list-table">
      <h3 matListItemTitle>
        <b>{{ title }}</b>
      </h3>

      <p matListItemLine *ngIf="isNum(selectedChargingProfile.amps_limit)">
        <span>{{ 'CURRENT' | translate }}</span>
        <span>
          {{ selectedChargingProfile.amps_limit }}
          {{ selectedChargingProfile.chargingRateUnit }}
        </span>
      </p>
      <p matListItemLine>
        <span>{{ 'DESCRIPTION' | translate }}</span>
        <span>
          {{
            cleanStringLimitation(selectedChargingProfile.limitation_reason)
              | translate
          }}
        </span>
      </p>
      <p matListItemLine>
        <span>{{ 'QUEUE_ORDER' | translate }}</span>
        <span>{{ selectedChargingProfile.QueueOrder + 1 }}</span>
      </p>
      <p matListItemLine>
        <span>{{ 'QUEUE_REASON' | translate }}</span>
        <span>{{ selectedChargingProfile.QueueReason }}</span>
      </p>
      <p matListItemLine *ngIf="selectedChargingProfile.NumberRetries">
        <span>{{ 'RETRIES' | translate }}</span>
        <span>{{ selectedChargingProfile.NumberRetries }}</span>
      </p>
    </mat-list-item>
  </mat-list>

  <mat-list class="custom-message" *ngIf="customMessage">
    <div>
      <h4>
        <b>{{ title }}</b>
      </h4>
      <p>
        <span>{{ customMessage }}</span>
      </p>
    </div>
  </mat-list>
</ng-template>
