<div class="text-black mb-4">
  <button mat-icon-button class="right" (click)="closeAndUpdateBarColor()">
    <mat-icon>close</mat-icon>
  </button>
  <div class="inline-flex w-full items-baseline">
    <h3 class="mb-2 mt-0">{{ getSessionTimeInSeconds() | convertSeconds }}</h3>
    <h4 class="mb-2 ml-2 mt-0">
      {{ 'FROM' | translate }}
      {{ formatTime(orderItem?.TimeStarted) }}
      {{ 'TO' | translate }}
      {{ formatTime(orderItem?.TimeFinished) }}
    </h4>
  </div>
  <table class="details-table w-full">
    <tr>
      <td>{{ 'KWH_CHARGED' | translate }}</td>
      <td>
        {{ orderItem?.ChargingUnitsConsumed | number : '1.2-2' }}
      </td>
    </tr>
    <tr>
      <td>{{ 'COST_CHARGING' | translate }}</td>
      <td>
        {{
          orderItem?.TotalPriceCharging
            | scCurrency : currency : 'symbol-narrow'
        }}
      </td>
    </tr>
    <tr>
      <td>{{ 'COST_OCCUPIED' | translate }}</td>
      <td>
        {{
          orderItem?.TotalPriceOccupied
            | scCurrency : currency : 'symbol-narrow'
        }}
      </td>
    </tr>
    <tr>
      <td>{{ 'Cost while charging' }}</td>
      <td>
        {{
          orderItem?.TotalPriceChargingTime
            | scCurrency : currency : 'symbol-narrow'
        }}
      </td>
    </tr>
    <tr>
      <td>{{ 'STARTUP_COST' | translate }}</td>
      <td>
        {{ orderItem?.StartupCost | scCurrency : currency : 'symbol-narrow' }}
      </td>
    </tr>
    <tr>
      <th>
        {{ 'TOTAL_PRICE_EXCLUDING_VAT' | translate }}
      </th>
      <th>
        {{
          orderItem?.TotalPriceExcludingVAT
            | scCurrency : currency : 'symbol-narrow'
        }}
      </th>
    </tr>
    <tr>
      <th>{{ 'TOTAL_PRICE' | translate }}</th>
      <th>
        {{ orderItem?.TotalPrice | scCurrency : currency : 'symbol-narrow' }}
      </th>
    </tr>
  </table>

  <div>
    <h4 class="mb-2">{{ 'Stats' }}</h4>
  </div>
  <table class="details-table w-full">
    <tr>
      <td>{{ 'DURATION_CHARGING' | translate }}</td>
      <td>
        {{ orderItem?.NbSecondsCharging | convertSeconds }}
      </td>
    </tr>
    <tr>
      <td>{{ 'DURATION_PAUSED' | translate }}</td>
      <td>
        {{ orderItem?.NbSecondsPaushed | convertSeconds }}
      </td>
    </tr>
    <tr>
      <td>{{ 'DURATION_OCCUPIED' | translate }}</td>
      <td>
        {{ orderItem?.NbSecondsOccupied | convertSeconds }}
      </td>
    </tr>
  </table>

  <div>
    <h4 class="mb-2">
      {{ 'PRICING_RULE' | translate }}
    </h4>
  </div>
  <table class="details-table w-full">
    <tr *ngIf="orderItem?.Pricing?.CostCharging && !orderItem.Pricing.HasVariablePricing">
      <td>{{ 'PRICE_KWH' | translate }}</td>
      <td>{{ orderItem?.Pricing?.CostCharging | scCurrency : currency : 'symbol-narrow' }}</td>
    </tr>
    <tr *ngIf="orderItem?.Pricing?.HasVariablePricing">
      <td>{{ 'SPOT_PRICE' | translate }} /{{ unitTypeNameShort.PerkWh }}</td>
      <td>
        <span class="link-style" (click)="showHistoricalSpotPrice()">
          {{ orderItem?.Pricing?.VariableCostPerKwhIncVAT || 0 | scCurrency : currency : 'symbol-narrow'}}
        </span>
      </td>
    </tr>
    <tr *ngIf="orderItem?.Pricing?.HasVariablePricing">
      <td>{{ 'PRICE_KWH' | translate }}</td>
      <td>{{ orderItem?.Pricing?.CostPerKWhUsedIncVAT | scCurrency : currency : 'symbol-narrow' }}</td>
    </tr>
    <tr *ngIf="orderItem?.Pricing?.HasVariablePricing">
      <td>{{ 'FIXED_FEE_ON_TOP' | translate }} /{{ unitTypeNameShort.PerkWh }}</td>
      <td>{{ orderItem?.Pricing?.FixedFeeOnTopPerKWH | scCurrency : currency : 'symbol-narrow' }}</td>
    </tr>
    <tr *ngIf="orderItem?.Pricing?.HasVariablePricing">
      <td>{{ 'PERCENTAGE_FEE_ON_TOP' | translate }} /{{ unitTypeNameShort.PerkWh }}</td>
      <td>{{ orderItem?.Pricing?.PercentageFeeOnTopPerKWH }}%</td>
    </tr>
    <tr *ngIf="orderItem?.Pricing?.HasVariablePricing">
      <td>{{ 'SINGLE_SESSION_TEXT_108' | translate }}</td>
      <td>
        @if(orderItem?.Pricing?.HasOperatorCutOnTop) {
          {{ 'YES' | translate }}
        } @else {
          {{ 'NO' | translate }}
        }
      </td>
    </tr>
    <tr *ngIf="orderItem?.Pricing?.CostOccupied">
      <td>{{ 'COST_OCCUPIED' | translate }} {{ getUnit(orderItem.Pricing.Type_Cost_Occupied) }}</td>
      <td>{{ orderItem?.Pricing?.CostOccupied | scCurrency : currency : 'symbol-narrow'}}</td>
    </tr>
    <tr *ngIf="orderItem?.Pricing?.CostChargingTime">
      <td>{{ 'PRICE_MIN' | translate }}</td>
      <td>{{ orderItem?.Pricing?.CostChargingTime | scCurrency : currency : 'symbol-narrow' }}</td>
    </tr>
    <tr *ngIf="orderItem?.Pricing?.StartupCost">
      <td>{{ 'STARTUP_COST' | translate }}</td>
      <td>{{ orderItem?.Pricing?.StartupCost | scCurrency : currency : 'symbol-narrow' }}</td>
    </tr>
  </table>
</div>
