<div class="dialog-container">
  <div class="flex items-baseline justify-between p-2">
    <div class="flex items-baseline">
      <h3 mat-dialog-title>{{ 'HISTORICAL_SPOT_PRICE' | translate }}</h3>
      <p class="ml-2 font-bold">{{ startDate.format('ll') }}</p>
    </div>
    <div class="ml-2 flex items-baseline">
      <button mat-raised-button (click)="changeDay('previous')">
        <mat-icon>skip_previous</mat-icon>
        {{ 'PREVIOUS_DAY' | translate }}
      </button>
      <button mat-raised-button (click)="changeDay('next')" class="!ml-2">
        <mat-icon>skip_next</mat-icon>
        {{ 'NEXT_DAY' | translate }}
      </button>
      <button mat-raised-button (click)="changeDay('today')" class="!ml-2">
        <mat-icon>calendar_today</mat-icon>
        {{ 'TODAY' | translate }}
      </button>
    </div>
  </div>

  <div mat-dialog-content class="dialog-content">
    <mat-spinner *ngIf="isLoading" class="spinner-center"></mat-spinner>
    <div
      class="mt-4 flex flex-col items-center justify-center"
      *ngIf="priceData.length === 0 && !isLoading">
      <mat-icon class="error-icon" color="error">error_outline</mat-icon>
      <p class="mt-6 text-xl">
        {{ 'HISTORICAL_SPOT_PRICE_TEXT_116' | translate }}
      </p>
    </div>
    <div [hidden]="isLoading || priceData.length === 0">
      <canvas class="max-h-[25vh]" #priceChart></canvas>
    </div>
    <div *ngIf="!isLoading" class="table-container w-full pb-4 pl-0 pr-0 pt-4">
      <ngx-datatable
        [rowHeight]="'auto'"
        [columnMode]="'force'"
        [rowHeight]="50"
        [ngStyle]="{ height: '300px' }"
        [scrollbarV]="true"
        [rows]="priceData"
        *ngIf="priceData.length"
        class="material no-click no-hover">
        <ngx-datatable-column
          prop="hour"
          name="{{ 'HOURS' | translate }}"
          [sortable]="true"></ngx-datatable-column>

        <ngx-datatable-column [sortable]="true">
          <ng-template ngx-datatable-header-template>
            <span class="inline-flex">
              {{ 'SPOT_PRICE' | translate }}
            </span>
          </ng-template>

          <ng-template let-hour="row" ngx-datatable-cell-template>
            {{
              hour.price.spotPrice
                | scCurrency : hour.price.currency : 'symbol-narrow'
            }}/kWh
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button mat-dialog-close type="button">
      {{ 'CLOSE' | translate }}
    </button>
  </div>
</div>
