import { Component, Inject } from "@angular/core";
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import moment from "moment";
import { UnitTypeName, UnitTypeNameShort, UnitTypesEnum } from "src/app/types/UnitType";
import { HistoricalSpotPriceComponent } from "../../spot-price/historical-spot-price/historical-spot-price.component";

@Component({
  selector: 'sc-order-item',
  templateUrl: 'order-item.component.html',
  styleUrls: ['./order-item.component.scss']
})
export class OrderItemComponent {
  orderItem: any = {};
  currency: string;
  unitTypeNameShort = UnitTypeNameShort;
  stationID = null

  constructor(
    private bottomSheetRef: MatBottomSheetRef<OrderItemComponent>,
    private dialog: MatDialog,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {
    this.orderItem = data.orderItem;
    this.currency = data.currency;
    this.stationID = data.stationID;
  }

  closeAndUpdateBarColor() {
    this.bottomSheetRef.dismiss();
  }

  getSessionTimeInSeconds = () => {
    return Math.round(
      moment(this.orderItem.TimeFinished).diff(
        moment(this.orderItem.TimeStarted),
        'seconds',
        true
      )
    );
  };

  formatTime = (time: string) => {
    return moment(time).format('LT');
  };

  showHistoricalSpotPrice = () => {
    let dialogRef: MatDialogRef<HistoricalSpotPriceComponent>;
    dialogRef = this.dialog.open(HistoricalSpotPriceComponent);
    dialogRef.componentInstance.stationID = this.stationID;
    dialogRef.componentInstance.startDate = moment(
      this.orderItem.TimeStarted
    );
    dialogRef.afterClosed().subscribe((res) => { });
  };

  getUnit = (unitType: UnitTypesEnum) => {
    const unitTypeEnum = UnitTypesEnum;
    const unitTypeName = Object.keys(UnitTypesEnum).filter(
      (item) => unitTypeEnum[item] == unitType
    )[0] as UnitTypeName;
    return UnitTypeName[unitTypeName];
  };
}
