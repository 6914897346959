export enum BootReasonType {
  ApplicationReset = 'BOOT_REASON_APPLICATION_RESET',
  FirmwareUpdate = 'BOOT_REASON_FIRMWARE_UPDATE',
  LocalReset = 'BOOT_REASON_LOCAL_RESET',
  PowerUp = 'BOOT_REASON_POWER_UP',
  RemoteReset = 'BOOT_REASON_REMOTE_RESET',
  ScheduledReset = 'BOOT_REASON_SCHEDULED_RESET',
  Triggered = 'BOOT_REASON_TRIGGERED',
  Unknown = 'BOOT_REASON_UNKNOWN',
  Watchdog = 'BOOT_REASON_WATCHDOG'
}
