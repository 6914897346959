import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { ActionHistoryTableModule } from '../action-history/action-history-table/action-history-table.module';
import { LimitationPopoverModule } from '../charging-limitations/limitation-popover/limitation-popover.module';
import { SingleSessionComponent } from './single-session.component';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatGridListModule } from '@angular/material/grid-list';
import { OrderItemComponent } from './order-item/order-item.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    PipesModule,
    DirectivesModule,
    FormsModule,
    RouterModule,
    MatTabsModule,
    NgxDatatableModule,
    LimitationPopoverModule,
    NgbModule,
    MatIconModule,
    MatListModule,
    ActionHistoryTableModule,
    MatRadioModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatToolbarModule,
    MatTooltipModule,
    MatGridListModule,
    MatBottomSheetModule
  ],
  declarations: [SingleSessionComponent, OrderItemComponent],
  providers: [DecimalPipe]
})
export class SingleSessionModule {}
