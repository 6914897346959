export type UnitType = 'PerkWh' | 'PerMinute' | 'PerHour' | 'Per15Minutes';
export const UnitTypes: UnitType[] = [
  'PerkWh',
  'PerMinute',
  'PerHour',
  'Per15Minutes'
];
export enum UnitValues {
  PerkWh = 'PerkWh',
  PerMinute = 'PerMinute',
  PerHour = 'PerHour',
  Per15Minutes = 'Per15Minutes'
}
export enum UnitTypeName {
  PerkWh = 'Per kWh',
  PerMinute = 'Per min',
  PerHour = 'Per hour',
  Per15Minutes = 'Per 15 min'
}
export enum UnitTypeNameShort {
  PerkWh = 'kWh',
  PerMinute = 'min',
  PerHour = 'hour',
  Per15Minutes = '15 min'
}
export interface Units {
  text: string;
  value: UnitType;
  disabled: boolean;
  onlyCharging: boolean;
}
export const AvailableUnits: Units[] = [
  { text: 'Per kWh', value: 'PerkWh', disabled: false, onlyCharging: true },
  { text: 'Per min', value: 'PerMinute', disabled: false, onlyCharging: false }
];

export enum UnitTypesEnum {
  PerkWh = 0,
  PerMinute = 1,
  PerHour = 2,
  Per15Minutes = 3
}
