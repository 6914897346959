/* Angular */

import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

/* Components */
import { MainSidenavComponent } from './components/main-sidenav/main-sidenav.component';

/* Authguard*/
import { AuthGuard } from './services/auth/auth-guard.service';
import { UserType } from './user-roles';
import { SingleSessionComponent } from './components/single-session/single-session.component';

/* --------------------------------------------------------------------------
    All routes of the app
*/
const MainSidenavComponentChildren = [
  //{ path: '', redirectTo: 'stations', pathMatch: 'full'},

  {
    path: '',
    loadChildren: () =>
      import('./components/home/home.module').then((m) => m.HomeModule),
    data: { title: 'Home' }
    // pathMatch: 'full'
  },

  {
    path: 'station/:stationID',
    loadChildren: () =>
      import('./components/single-station/single-station.module').then(
        (m) => m.SingleStationModule
      ),
    data: { title: 'Location' }
  },

  {
    path: 'station/:stationID/settings/rules',
    loadChildren: () =>
      import('./components/rules/rules.module').then((m) => m.RulesModule),
    data: { title: 'Rules' }
  },
  {
    path: 'station/:stationID/settings/limitations',
    loadChildren: () =>
      import(
        './components/charging-limitations/charging-limitations.module'
      ).then((m) => m.ChargingLimitationsModule),
    data: { title: 'Limitations' }
  },
  {
    path: 'station/:stationID/point/:pointID',
    loadChildren: () =>
      import('./components/single-point/single-point.module').then(
        (m) => m.SinglePointModule
      ),
    data: { title: 'Point' }
  },
  {
    path: 'station/:stationID/settings',
    loadChildren: () =>
      import(
        './components/single-station/station-settings/station-settings.module'
      ).then((m) => m.StationSettingsModule),
    data: { title: 'Settings' }
  },
  //{ path: 'station/:stationID/settings/permissions', loadChildren: './components/permissions/permissions/permissions.module#PermissionsModule', data: {title: 'Permissions'}},
  {
    path: 'station/:stationID/command-responses',
    loadChildren: () =>
      import('./components/command-responses/command-responses.module').then(
        (m) => m.CommandResponsesModule
      ),
    data: { title: 'Command responses' }
  },
  {
    path: 'station/:stationID/point/:pointId/:chargingBoxId/command-responses',
    loadChildren: () =>
      import('./components/command-responses/command-responses.module').then(
        (m) => m.CommandResponsesModule
      ),
    data: { title: 'Command responses' }
  },
  {
    path: 'station/:stationID/circuits',
    loadChildren: () =>
      import('./components/wall-settings/wall-settings.module').then(
        (m) => m.WallSettingsModule
      ),
    data: { title: 'Circuits' }
  },
  {
    path: 'station/:stationID/terminals',
    loadChildren: () =>
      import('./components/payment-terminals/payment-terminals.module').then(
        (m) => m.PaymentTerminalsModule
      ),
    data: { title: 'Payment Terminals' }
  },
  {
    path: 'station/:stationID/reservations',
    loadChildren: () =>
      import('./components/reservations/reservations.module').then(
        (m) => m.ReservationsModule
      ),
    data: { title: 'Reservations' }
  },
  {
    path: 'stations',
    loadChildren: () =>
      import('./components/stations/stations.module').then(
        (m) => m.StationsModule
      ),
    data: { title: 'Locations' }
  },

  {
    path: 'add',
    loadChildren: () =>
      import('./components/add/add.module').then((m) => m.AddModule),
    data: { title: 'Add' }
  },
  {
    path: 'invoices',
    loadChildren: () =>
      import('./components/invoices/invoices.module').then(
        (m) => m.InvoicesModule
      ),
    data: { title: 'Invoices' }
  },

  {
    path: 'reports',
    loadChildren: () =>
      import('./components/reports/reports.module').then(
        (m) => m.ReportsModule
      ),
    data: { title: 'Reports' }
  },

  {
    path: 'insights',
    loadChildren: () =>
      import('./components/insights/insights.module').then(
        (m) => m.InsightsModule
      ),
    data: { title: 'Insights', roles: UserType.SuperUser }
  },

  {
    path: 'groups',
    loadChildren: () =>
      import('./components/groups/groups.module').then((m) => m.GroupsModule),
    data: { title: 'Groups' }
  },

  {
    path: 'points',
    loadChildren: () =>
      import('./components/points/points.module').then((m) => m.PointsModule),
    data: { title: 'Points' }
  },
  {
    path: 'stickers',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('./components/qr-codes/qr-codes.module').then(
        (m) => m.QrCodesModule
      ),
    data: { title: 'Stickers', roles: UserType.SuperUser }
  },

  {
    path: 'users',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('./components/users/users.module').then((m) => m.UsersModule),
    data: { title: 'Users', roles: UserType.CanEditUsers }
  },
  {
    path: 'companies',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('./components/companies/companies.module').then(
        (m) => m.CompaniesModule
      ),
    data: {
      title: 'Companies',
      roles: [UserType.SuperUser, UserType.Admin, UserType.Operator]
    }
  },
  //{ path: 'users/:customerID', loadChildren: './components/users/user/user.module#UserModule', data: {title: 'User'}},
  {
    path: 'users/:customerID/invoice',
    loadChildren: () =>
      import('./components/invoices/invoices.module').then(
        (m) => m.InvoicesModule
      ),
    data: { title: 'Invoices' }
  },
  {
    path: 'users/:customerID',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('./components/admin/users/user/user.module').then(
        (m) => m.UserModule
      ),
    data: { title: 'User', roles: UserType.CanEditUsers }
  },
  {
    path: 'companies/:customerID',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('./components/companies/companies.module').then(
        (m) => m.CompaniesModule
      ),
    data: {
      title: 'Company',
      roles: [UserType.SuperUser, UserType.Admin, UserType.Operator]
    }
  },
  {
    path: 'billing',
    loadChildren: () =>
      import('./components/billing/billing.module').then(
        (m) => m.BillingModule
      ),
    data: { title: 'Billing' }
  },

  {
    path: 'settings',
    loadChildren: () =>
      import('./components/settings/settings.module').then(
        (m) => m.SettingsModule
      ),
    data: {}
  },

  {
    path: 'not-found/:url',
    loadChildren: () =>
      import('./components/not-found/not-found.module').then(
        (m) => m.NotFoundModule
      ),
    data: { title: 'Not found' }
  },
  {
    path: 'package-templates',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('./components/package-templates/package-templates.module').then(
        (m) => m.PackageTemplatesModule
      ),
    data: {
      title: 'Package templates',
      roles: [UserType.SuperUser, UserType.Operator]
    }
  },
  {
    path: 'product-templates',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import(
        './components/products/product-templates/product-templates.module'
      ).then((m) => m.ProductTemplatesModule),
    data: {
      title: 'Product templates',
      roles: [UserType.Admin, UserType.Operator]
    }
  },
  {
    path: 'bulk-operations',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('./components/batch-commands/batch-commands.module').then(
        (m) => m.BatchCommandsModule
      ),
    data: { title: 'Bulk operations' }
  },
  {
    path: 'sessions/:sessionID',
    component: SingleSessionComponent,
    data: {
      title: 'Charging session',
      component: 'Session',
      hlAccess: [UserType.Operator, UserType.Installer]
    },
    canActivate: [AuthGuard]
  }
  //{ path: '**', loadChildren: './components/not-found/not-found.module#NotFoundModule', data: {title: 'Not found'}}
];

/* All pages of the app except auth are rendered in MainSidenavComponent */
export const routes: Routes = [
  {
    path: '',
    component: MainSidenavComponent,
    children: MainSidenavComponentChildren
  },
  {
    path: 'admin',
    component: MainSidenavComponent,
    data: { title: 'Admin', roles: UserType.Admin },
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./components/admin/admin.module').then((m) => m.AdminModule),
        data: { title: 'Admin', roles: UserType.Admin }
      }
    ]
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./components/admin/admin.module').then((m) => m.AdminModule),
    data: { title: 'Admin', roles: UserType.Admin }
  },
  /* Auth */
  {
    path: 'auth',
    loadChildren: () =>
      import('./components/auth/auth.module').then((m) => m.AuthModule)
  },

  {
    path: 'status',
    loadChildren: () =>
      import('./components/status-page/status-page.module').then(
        (m) => m.StatusPageModule
      )
  },

  {
    path: 'box-test',
    loadChildren: () =>
      import('./components/ocpp-box-test/ocpp-box-test.module').then(
        (m) => m.OcppBoxTestModule
      ),
    data: { title: 'Test charger', roles: UserType.BoxManufacturer }
  },
  /* 404 Not found */
  /* NB! Must be last! */
  { path: '**', redirectTo: '', pathMatch: 'full' }
];
/* -------------------------------------------------------------------------- */
