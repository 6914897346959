<mat-toolbar class="page-toolbar">
  <mat-toolbar-row>
    <h3>{{ session?.PointName }}</h3>
    <span class="toolbar-filler"></span>
    <button
      mat-icon-button
      matTooltip="{{ 'REFRESH' | translate }}"
      *ngIf="!refreshing"
      (click)="fetchSessionAndOrder(true)">
      <mat-icon>refresh</mat-icon>
    </button>
    <mat-spinner class="small-spinner" *ngIf="refreshing"></mat-spinner>
  </mat-toolbar-row>
  <mat-toolbar-row>
    <a (click)="goBack()" class="icon-link">
      <mat-icon>arrow_back</mat-icon>
      {{ 'BACK' | translate }}
    </a>
  </mat-toolbar-row>
</mat-toolbar>

<div class="container-fluid-2">
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 signle-session">
      <div class="flex flex-wrap">
        <div class="w-full pb-2 pr-2 md:w-1/2">
          <mat-card class="full-content no-title">
            <mat-card-content>
              <mat-toolbar class="card-toolbar">
                <span class="toolbar-title">Session details</span>
                <span class="toolbar-filler"></span>
                <mat-icon
                  class="txt-gray mx-2"
                  *ngIf="session?.IsOfflineSession"
                  matTooltip="{{ 'OFFLINE_SESSION' | translate }}">
                  cloud_off
                </mat-icon>
                <mat-icon
                  class="txt-warn mx-2"
                  *ngIf="session?.HasWarningsOrErrors"
                  matTooltip="{{ 'SESSION_HAS_WARNINGS' | translate }}">
                  warning
                </mat-icon>
                @switch (session?.StartMethod) {
                  @case ('SMS') {
                    <mat-icon class="txt-gray mx-2" matTooltip="{{ 'STARTED_WITH_SMS' | translate }}">sms</mat-icon>
                  }
                  @case ('RFID') {
                    <mat-icon class="txt-gray mx-2" matTooltip="{{ 'STARTED_WITH_RFID' | translate }}">
                      settings_remote
                    </mat-icon>
                  }
                  @case ('QR') {
                    <mat-icon class="txt-gray mx-2" matTooltip="{{ 'STARTED_WITH_QR' | translate }}">
                      border_outer
                    </mat-icon>
                  }
                  @case ('App') {
                    <mat-icon class="txt-gray mx-2" matTooltip="{{ 'STARTED_WITH_APP' | translate }}">
                      phone_iphone
                    </mat-icon>
                  }
                  @case ('ROAMING_REMOTE_START') {
                    <mat-icon class="txt-gray mx-2" matTooltip="{{ 'STARTED_WITH_ROAMING_EMSP' | translate }}">
                      phone_iphone
                    </mat-icon>
                  }
                  @case ('ROAMING_LOCAL_START') {
                    <mat-icon class="txt-gray mx-2" matTooltip="{{ 'STARTED_WITH_ROAMING_TAG' | translate }}">
                      settings_remote
                    </mat-icon>
                  }
                  @case ('Dashboard') {
                    <mat-icon class="txt-gray mx-2" matTooltip="{{ 'STARTED_FROM_DASHBOARD' | translate }}">
                      tv
                    </mat-icon>
                  }
                  @case ('PLUG_AND_CHARGE') {
                    <mat-icon class="txt-gray mx-2" matTooltip="{{ 'STARTED_AUTOMATICALLY' | translate }}">
                      power
                    </mat-icon>
                  }
                  @default {
                    <mat-icon class="txt-gray mx-2" matTooltip="{{ 'UNKNOWN_START_METHOD' | translate }}">
                      power
                    </mat-icon>
                  }
                }
              </mat-toolbar>
              <div class="flex" *ngIf="!loadingDatas">
                <mat-list>
                  <mat-list-item>
                    <mat-icon matListItemIcon>timer</mat-icon>
                    <div matListItemTitle>{{ 'STARTED' | translate }}</div>
                    <div matListItemLine>{{ session?.SessionStart | cDate: 'L LT' }}</div>
                  </mat-list-item>
                  <mat-list-item>
                    <mat-icon matListItemIcon>history</mat-icon>
                    <div matListItemTitle>{{ 'ENDED' | translate }}</div>
                    <div matListItemLine>{{ session?.SessionEnd | cDate: 'L LT' }}</div>
                  </mat-list-item>
                  <mat-list-item>
                    <mat-icon matListItemIcon>schedule</mat-icon>
                    <div matListItemTitle>{{ 'DURATION' | translate }}</div>
                    <div matListItemLine>{{ session?.Duration | convertSeconds }}</div>
                  </mat-list-item>
                </mat-list>
                <mat-list>
                  <mat-list-item>
                    <mat-icon matListItemIcon>speed</mat-icon>
                    <div matListItemTitle>{{ 'KWH' | translate }}</div>
                    <div matListItemLine>{{ session?.TotalkWh | number: '1.2-2' }}</div>
                  </mat-list-item>
                  <mat-list-item>
                    <mat-icon matListItemIcon>stop_circle</mat-icon>
                    <div matListItemTitle>{{ 'STOP_REASON' | translate }}</div>
                    <div matListItemLine>{{ session?.StopReason }}</div>
                  </mat-list-item>
                  <mat-list-item *ngIf="showPaymentStatus">
                    <mat-icon matListItemIcon>paid</mat-icon>
                    <div matListItemTitle>{{ 'PAYMENT_STATUS' | translate }}</div>
                    <div matListItemLine>{{ paymentStatus }}</div>
                  </mat-list-item>
                </mat-list>
              </div>
              <mat-spinner *ngIf="loadingDatas" class="spinner-center"></mat-spinner>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="w-full pb-2 pr-2 md:w-1/2">
          <mat-card class="full-content no-title">
            <mat-card-content>
              <mat-toolbar class="card-toolbar">
                <span class="toolbar-title">Other information</span>
                <span class="toolbar-filler"></span>
              </mat-toolbar>
              <div class="flex" *ngIf="!loadingDatas">
                <mat-list>
                  <mat-list-item>
                    @if (order.CustomerNote === 'Adhoc') {
                      <mat-icon matListItemIcon matTooltip="{{ 'ADHOC_USER' | translate }}">record_voice_over</mat-icon>
                    } @else {
                      <mat-icon matListItemIcon>person</mat-icon>
                    }
                    <div matListItemTitle>{{ 'USER' | translate }}</div>
                    <div matListItemLine>
                      @if (session?.FK_CustomerID !== 158) {
                        <span *canNotSee="userType.Admin">
                          {{ displayName(session) }}
                        </span>
                        <a *canSee="userType.Admin" routerLink="/users/{{ session?.FK_CustomerID }}">
                          {{ displayName(session) }}
                        </a>
                      } @else if (session?.FK_CustomerID === 158) {
                        {{ 'UNAUTHENTICATED_CUSTOMER' | translate }}
                      }
                    </div>
                  </mat-list-item>

                  <mat-list-item>
                    <mat-icon matListItemIcon>place</mat-icon>
                    <div matListItemTitle>{{ 'Location' | translate }}</div>
                    <div matListItemLine>
                      <a routerLink="/station/{{ order?.StationID }}">
                        {{ order?.StationName }}
                      </a>
                    </div>
                  </mat-list-item>
                  <mat-list-item>
                    <mat-icon matListItemIcon>power</mat-icon>
                    <div matListItemTitle>{{ 'POINT' | translate }}</div>
                    <div matListItemLine>
                      <a routerLink="/station/{{ order?.StationID }}/point/{{ order?.ChargePointID }}/sessions">
                        {{ order?.ChargePointName }}
                      </a>
                    </div>
                  </mat-list-item>
                </mat-list>
                <mat-list>
                  <mat-list-item *ngIf="order.CustomerNote === 'Roaming'">
                    <img matListItemIcon [src]="roamingPlatformByID[order.CustomerRoamingPlatformID]?.UrlIcon" />
                    <div matListItemTitle>
                      {{
                        'ROAMING_WITH_PLATFORM'
                          | translate
                            : {
                                platform: roamingPlatformByID[order.CustomerRoamingPlatformID]?.Name || ''
                              }
                      }}
                    </div>
                  </mat-list-item>
                </mat-list>
                <div>
                  <div class="absolute right-4 top-4 h-auto w-14 rounded-md text-right md:w-28">
                    <img class="h-full w-full object-cover" [src]="chargingBoxModel?.PathImage" />
                    <p class="font-semibold text-center ng-star-inserted">
                      {{ chargingBoxModel?.ManufacturerName || chargingBoxModel?.VendorName }}
                    </p>
                  </div>
                </div>
              </div>
              <mat-spinner *ngIf="loadingDatas" class="spinner-center"></mat-spinner>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="w-full pb-2 pr-2 md:w-1/2">
          <mat-card class="h-[450px]">
            <mat-card-content>
              <div class="txt-center charts" *ngIf="!graphData.noData">
                <mat-radio-group (change)="graphChange($event)">
                  <mat-radio-button [checked]="defaultSessionGraphUnit === GraphType.KW" [value]="GraphType.KW">
                    {{ 'KW' | translate }}
                  </mat-radio-button>
                  <mat-radio-button [checked]="defaultSessionGraphUnit === GraphType.Amps" [value]="GraphType.Amps">
                    {{ 'AMPERE' | translate }}
                  </mat-radio-button>
                  <mat-radio-button [checked]="defaultSessionGraphUnit === GraphType.KWH" [value]="GraphType.KWH">
                    {{ 'KWH' | translate }}
                  </mat-radio-button>
                  <mat-radio-button
                    *ngIf="graphData?.SoC?.points?.length"
                    [checked]="defaultSessionGraphUnit === GraphType.SoC"
                    [value]="GraphType.SoC">
                    {{ 'BATTERY_PERCENT' | translate }}
                  </mat-radio-button>
                  <mat-radio-button
                    *canSee="userType.SuperUser"
                    [checked]="defaultSessionGraphUnit === GraphType.DETAILS"
                    [disabled]="sessionOrderItems.length === 0"
                    [value]="GraphType.DETAILS">
                    {{ 'PRICING_DETAILS' | translate }}
                  </mat-radio-button>
                </mat-radio-group>
              </div>

              <mat-spinner *ngIf="loadingDatas" class="spinner-center"></mat-spinner>
              <div *ngIf="!graphData.noData">
                <div class="inline-flex w-full justify-center">
                  <div class="w-full" [ngClass]="{ hidden: !showPricingDetailsChart }">
                    <canvas #sessionGraph></canvas>
                  </div>
                </div>
              </div>

              <p class="txt-center no-meter-data" *ngIf="graphData.noData">
                {{ 'SINGLE_SESSION_TEXT_110' | translate }}
              </p>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="w-full pb-2 pr-2 md:w-1/2">
          <mat-card class="full-content no-title">
            <mat-card-content>
              <mat-toolbar class="card-toolbar">
                <span class="toolbar-title">{{ 'LOGS' | translate }}</span>
              </mat-toolbar>
              <sc-action-history-table
                [scrollable]="true"
                [fixedHeight]="'354px'"
                [actions]="actions"
                [tableOptions]="tableOptions"
                [loading]="loading"
                [refreshing]="refreshing"
                (page)="onPage($event)"></sc-action-history-table>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</div>
