interface DownloadFileOptions {
  blob: Blob;
  fileName: string;
  target?: string;
}

/*
 * Utility for downloading blobs as files
 */
export function downloadFile({
  blob,
  fileName,
  target = '_blank'
}: DownloadFileOptions) {
  const url = window.webkitURL.createObjectURL(blob);
  window.open(url, 'Download', 'popup,noopener');
}

/**
 *
 * Utility for sanitizing object
 */
export const sanitizeObject = (obj) => {
  return Object.entries(obj)
    .filter(([_, value]) => !!value && value)
    .reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: value === Object(value) ? sanitizeObject(value) : value
      }),
      {}
    );
}
