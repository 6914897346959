import { Component } from '@angular/core';

@Component({
  selector: 'sc-json-viewer',
  templateUrl: './json-viewer.component.html',
  styleUrls: ['./json-viewer.component.scss']
})
export class JsonViewerComponent {
  title: string;
  json: any;
  date: any;
  raw: boolean = false;

  constructor() {}

  ngOnInit() {
    let isRaw = localStorage.getItem('rawJson') == '1';
    this.raw = isRaw ? true : false;
  }
  toggleRaw() {
    this.raw = !this.raw;
    localStorage.setItem('rawJson', this.raw ? '1' : '0');
  }
}
