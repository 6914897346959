export enum AppEnvironment {
  LOCAL = 'local',
  STAGING = 'staging',
  SANDBOX = 'sandbox',
  QA = 'qa',
  PRODUCTION = 'production'
}

export class AppEnv {
  getAppEnviroment() {
    const env = window.location.hostname.toLowerCase();
    if (env.includes('local') || env.includes('127.0.0.1')) {
      return AppEnvironment.LOCAL;
    } else if (env.includes('staging')) {
      return AppEnvironment.STAGING;
    } else if (env.includes('sandbox') || env.includes('agreeable-bush-05b6fad03.4')) {
      return AppEnvironment.SANDBOX;
    } else if (env.includes('qa')) {
      return AppEnvironment.QA;
    } else {
      return AppEnvironment.PRODUCTION;
    }
  }



  envStagingOrLocal = () => {
    const env = this.getAppEnviroment();
    return env === AppEnvironment.STAGING || env === AppEnvironment.LOCAL;
  }

  isStagingEnvironmnet() {
    const env = localStorage.getItem('environment') || AppEnvironment.PRODUCTION;
    return env === AppEnvironment.STAGING || env === AppEnvironment.LOCAL;
  }
}
