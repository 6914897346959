<h2 mat-dialog-title>
  {{ title }}
  <span class="json-date" *ngIf="date">{{ date | cDate : 'LTS L' }}</span>
  <button mat-button class="margin-left" (click)="toggleRaw()">
    <span *ngIf="raw">{{ 'SHOW_PRETTY' | translate }}</span>
    <span *ngIf="!raw">{{ 'SHOW_RAW' | translate }}</span>
  </button>
</h2>

<mat-dialog-content>
  <ngx-json-viewer
    *ngIf="json && !raw"
    [json]="json"
    [expanded]="false"></ngx-json-viewer>

  <pre *ngIf="json && raw">{{ json | json }}</pre>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close type="button">
    {{ 'CLOSE' | translate }}
  </button>
</mat-dialog-actions>
