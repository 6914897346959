import { Pipe, PipeTransform } from '@angular/core';
import { AppCardPipe } from '../app-card/app-card.pipe';
import { TranslateService } from '@ngx-translate/core';
import { AutohealReasonLabel } from 'src/app/types/Actions';

const boxAnswers = [
  'REMOTE_START_ANSWER',
  'REMOTE_STOP_ANSWER',
  'REMOTE_RESET_ANSWER',
  'REMOTE_CHANGE_AVAILABILITY_ANSWER',
  'REMOTE_CLEAR_CACHE_ANSWER',
  'REMOTE_CLEAR_CHARGING_PROFILE_ANSWER',
  'ChangePassword_Answer',
  'UNLOCKCONNECTOR_ANSWER'
];

@Pipe({
  name: 'action'
})
export class ActionPipe implements PipeTransform {
  constructor(
    private appCard: AppCardPipe,
    private translate: TranslateService
  ) {}

  transform(action: any, field?: any): any {
    if (action) {
      if (field === 'type') {
        action = this.transformType(action);
      } else if (field === 'rfid') {
        if (action === 'N/A') {
          action = '-';
        }
      } else if (field === 'extra') {
        action = this.transformDatas(action);
      } else if (field === 'status') {
        action = this.transformStatus(action);
      } else if (field === 'icon') {
        return this.transformIcon(action);
      }
    }
    return action;
  }
  transformIcon(action) {
    if (!action || !action.typeLog) return;

    switch (action.typeLog) {
      case 'RFID_SWIPED':
        return 'sensors';
      case 'TRANSACTION_REQUEST':
        return 'record_voice_over';
      case 'STATUS_NOTIFICATION':
        if (action.IsError) {
          return 'report';
        } else if (action.datas && action.datas.Status) {
          switch (action.datas.Status) {
            case 'Charging':
              return 'battery_charging_full';
            case 'Unavailable':
              return 'block';
            case 'SuspendedEV':
              return 'pause_circle_outline';
            case 'SuspendedEVSE':
              return 'pause_circle_outline';
            default:
              return 'info_outline';
          }
        } else {
          return 'info_outline'; //default
        }
      case 'BOOT_NOTIFICATION':
        return 'power_settings_new';
      case 'STOP_TRANSACTION':
        return 'power_off';
      case 'START_TRANSACTION':
        return 'power';
      case 'NEW_CHARGING_PROFILE':
        return 'multiline_chart';
      case 'AUTHENTICATION':
        if (action.errorCode === 'AuthorizationNewCardRegistered') {
          return 'style';
        }
        return 'lock';
      case 'RESET_REQUESTED':
        return 'sync';
      case 'FIRMWARE_UPDATE':
        return 'backup';
      case 'TRANSACTION_ERROR':
        return 'report';
      case 'STOP_TRANSACTION_REQUESTED':
        return 'power_off';
      case 'BOX_ERROR':
        return 'report';
      case 'TRANSACTION_WARNING':
        return 'warning';
      case 'CANCELRESERVATION_REQUESTED':
        return 'highlight_off';
      case 'RESERVENOW_REQUESTED':
        return 'assignment_ind';
      case 'DATATRANSFER_REQUESTED':
        return 'trending_flat';
      case 'TRIGGERMESSAGE_REQUESTED':
        return 'announcement';
      case 'CLEARCHARGINGPROFILES_REQUESTED':
      case 'CLEARCACHE_REQUESTED':
      case 'REMOTE_DELETE_CERTIFICATE_REQUEST':
      case 'SET_CHARGING_PROFILE_REQUESTED':
        return 'delete_sweep';
      case 'SET_CHARGING_PROFILE_REQUESTED':
      case 'REMOTE_GET_CHARGING_PROFILES_REQUESTED':
        return 'post_add';
      case 'UNLOCKCONNECTOR_ANSWER':
      case 'UNLOCKCONNECTOR_REQUESTED':
        return 'lock_open';
      case 'CHANGEAVAILABILITY_REQUESTED':
        return 'info';
      case 'GETCOMPOSITESCHEDULE_REQUESTED':
      case 'REMOTE_GETCOMPOSITESCHEDULE_REQUESTED':
        return 'insert_chart_outlined';
      case 'GETDIAGNOSTICS_REQUESTED':
      case 'REMOTE_GET_LOG_REQUEST':
        return 'timeline';
      case 'SENDLOCALLIST_REQUESTED':
        return 'playlist_add';
      case 'GETLOCALLISTVERSION_REQUESTED':
        return 'list';
      case 'UPDATEFIRMWARE_REQUESTED':
        return 'backup';
      case 'GETCONFIGURATION_REQUESTED':
      case 'CHANGECONFIGURATION_REQUESTED':
      case 'CHANGECONFIGURATION_ANSWER':
      case 'REMOTE_SET_VARIABLE_MONITORING_REQUESTED':
      case 'REMOTE_SET_MONITORING_LEVEL_REQUESTED':
      case 'REMOTE_SET_MONITORING_BASE_REQUESTED':
        return 'settings';
      case 'CONNECTOR_DISCONNECTED':
        return 'cloud_off';
      case 'PERMANENT_LOCKING':
        if (action.datas) {
          //Check if enabled or not
          let enabled = action.datas.Permanent_Locking_Enabled;
          if (typeof enabled == 'string') {
            //Can be sent as string
            enabled = enabled.toLowerCase() == 'true';
          }
          return enabled ? 'lock' : 'lock_open';
        }
        return 'lock'; //Default
      case 'REMOTE_START_ANSWER':
      case 'REMOTE_STOP_ANSWER':
      case 'REMOTE_RESET_ANSWER':
      case 'REMOTE_CHANGE_AVAILABILITY_ANSWER':
      case 'REMOTE_CLEAR_CACHE_ANSWER':
      case 'REMOTE_CLEAR_CHARGING_PROFILE_ANSWER':
      case 'REMOTE_CLEAR_DISPLAY_MESSAGE_ANSWER':
      case 'REMOTE_SET_VARIABLE_MONITORING_ANSWER':
      case 'REMOTE_SET_MONITORING_LEVEL_ANSWER':
      case 'REMOTE_SET_MONITORING_BASE_ANSWER':
      case 'REMOTE_CLEAR_VARIABLE_MONITORING_ANSWER':
      case 'REMOTE_GETCOMPOSITESCHEDULE_ANSWER':
      case 'REMOTE_CUSTOMER_INFORMATION_ANSWER':
      case 'REMOTE_GET_MONITORING_REPORT_ANSWER':
      case 'REMOTE_GET_LOG_ANSWER':
      case 'REMOTE_SET_NETWORK_PROFILE_ANSWER':
      case 'REMOTE_GET_REPORT_ANSWER':
      case 'REMOTE_GET_BASE_REPORT_ANSWER':
      case 'REMOTE_GET_CHARGING_PROFILES_ANSWER':
      case 'REMOTE_CANCELRESERVATION_ANSWER':
      case 'CLEARED_CHARGING_LIMIT_ANSWER':
      case 'SENDLOCALLIST_ANSWER':
      case 'INSTALL_CERTIFICATE_ANSWER':
      case 'SECURITY_EVENT_NOTIFICATION_ANSWER':
        return 'reply';
      case 'REMOTE_GET_DISPLAY_MESSAGE_REQUESTED':
      case 'REMOTE_SET_DISPLAY_MESSAGE_REQUESTED':
        return 'add_to_home_screen';
      case 'REMOTE_GET_DISPLAY_MESSAGE_ANSWER':
      case 'REMOTE_SET_DISPLAY_MESSAGE_ANSWER':
        return 'smart_screen';
      case 'REMOTE_GET_TRANSACTION_STATUS_REQUESTED':
        return 'queue_play_next';
      case 'REMOTE_GET_TRANSACTION_STATUS_ANSWER':
        return 'wysiwyg';
      case 'AUTOHEAL':
        return 'auto_fix_high';
      case 'ChangePassword_Answer':
        return 'lock_reset';
      case 'ChangePassword_Requested':
        return 'lock';
      case 'REMOTE_CLEAR_DISPLAY_MESSAGE_REQUESTED':
        return 'cancel_presentation';
      case 'REMOTE_CLEAR_VARIABLE_MONITORING_REQUEST':
        return 'cancel';
      case 'REMOTE_CUSTOMER_INFORMATION_REQUESTED':
        return 'person';
      case 'REMOTE_GET_MONITORING_REPORT_REQUEST':
      case 'REMOTE_GET_REPORT_REQUEST':
      case 'REMOTE_GET_BASE_REPORT_REQUESTED':
        return 'summarize';
      case 'REMOTE_SET_NETWORK_PROFILE_REQUESTED':
        return 'network_wifi';
      case 'INSTALL_CERTIFICATE_REQUESTED':
        return 'system_update';
      case 'REMOTE_GET_INSTALLED_CERTIFICATE_IDS_REQUEST':
        return 'get_app';
      case 'FIRMWARE_STATUS_NOTIFICATION':
        return 'info_outline';
      case 'NEW_FIRMWARE':
        return 'grade';
      default:
        return;
    }

    //error = report
  }
  checkStatusNotification(action) {
    let error = this.checkError(action);
    if (error) {
      return error;
    } else if (action.datas && action.datas.Status) {
      return this.translate.instant(action.datas.Status.toUpperCase());
    } else {
      return this.translate.instant('STATUS_NOTIFICATION'); //default
    }
  }
  transformType(action) {
    if (!action.typeLog || !isNaN(action.typeLog)) return;
    switch (action.typeLog) {
      case 'STATUS_NOTIFICATION':
        return this.checkStatusNotification(action);
      case 'BOOT_NOTIFICATION':
        return this.translate.instant('BOOT');
      case 'STOP_TRANSACTION':
        return this.translate.instant('SESSION_STOPPED');
      case 'START_TRANSACTION':
        return this.translate.instant('SESSION_STARTED');
      case 'AUTHENTICATION':
        if (action.errorCode === 'AuthorizationNewCardRegistered') {
          return this.translate.instant('RFID_ADDED');
        }
        return this.translate.instant('AUTHENTICATION');

      case 'PERMANENT_LOCKING':
        if (action.datas) {
          //Check if enabled or not
          let enabled = action.datas.Permanent_Locking_Enabled;
          if (typeof enabled == 'string') {
            //Can be sent as string
            enabled = enabled.toLowerCase() == 'true';
          }
          return enabled
            ? this.translate.instant('PERMANENT_LOCKING_ENABLED')
            : this.translate.instant('PERMANENT_LOCKING_DISABLED');
        }
        return this.translate.instant('PERMANENT_LOCKING_CHANGED');

      case 'REMOTE_START_ANSWER':
        return this.translate.instant('REMOTE_START_ANSWER');
      case 'REMOTE_STOP_ANSWER':
        return this.translate.instant('REMOTE_STOP_ANSWER');
      case 'REMOTE_RESET_ANSWER':
        return this.translate.instant('REMOTE_RESET_ANSWER');
      case 'REMOTE_CHANGE_AVAILABILITY_ANSWER':
        return this.translate.instant('REMOTE_CHANGE_AVAILABILITY_ANSWER');
      case 'REMOTE_CLEAR_CACHE_ANSWER':
        return this.translate.instant('REMOTE_CLEAR_CACHE_ANSWER');
      case 'REMOTE_CLEAR_CHARGING_PROFILE_ANSWER':
        return this.translate.instant('REMOTE_CLEAR_CHARGING_PROFILE_ANSWER');

      case 'REMOTE_GET_DISPLAY_MESSAGE_ANSWER':
        return this.translate.instant('REMOTE_GET_DISPLAY_MESSAGE_ANSWER');

      case 'REMOTE_GET_TRANSACTION_STATUS_ANSWER':
        return this.translate.instant('REMOTE_GET_TRANSACTION_STATUS_ANSWER', {
          numberOfMessages: action.datas.messagesInQueue
        });
      case 'ChangePassword_Answer':
        return this.translate.instant('CHANGEPASSWORD_ANSWER');
      case 'ChangePassword_Requested':
        return this.translate.instant('CHANGEPASSWORD_REQUESTED');
      case 'NEW_FIRMWARE':
        return this.translate.instant('NEW_FIRMWARE_DETECTED');
      default:
        return this.translate.instant(action.typeLog);
    }
  }

  getStartedFrom(startMethod: string) {
    switch (startMethod) {
      case 'SMS':
        return this.translate.instant('STARTED_WITH_SMS');
      case 'RFID':
        return this.translate.instant('STARTED_WITH_RFID');
      case 'QR':
        return this.translate.instant('STARTED_WITH_QR');
      case 'App':
        return this.translate.instant('STARTED_WITH_APP');
      case 'Dashboard':
        return this.translate.instant('STARTED_FROM_DASHBOARD');
      case 'PLUG_AND_CHARGE':
        return this.translate.instant('STARTED_AUTOMATICALLY');
      case 'ROAMING_REMOTE_START':
        return this.translate.instant('STARTED_WITH_ROAMING_EMSP');
      case 'ROAMING_LOCAL_START':
        return this.translate.instant('STARTED_WITH_ROAMING_TAG');
      case 'PAYMENT_TERMINAL':
        return this.translate.instant('PAYMENT_TERMINAL');
      default:
        return this.translate.instant('UNKNOWN_START_METHOD');
    }
  }

  transformDatas(action) {
    let description = '';

    if (!action) return action;

    if (action.typeLog === 'NEW_CHARGING_PROFILE') {
      if (action.datas.amps_limit <= 2) {
        description = this.translate.instant('PAUSING');
      } else {
        description = action.datas.amps_limit + 'A';
      }
    } else if (action.typeLog === 'AUTOHEAL') {
      return this.translate.instant(AutohealReasonLabel[action.datas.reason]);
    } else {
      description = this.transformErrorCode(action);
    }

    if (action.datas) {
      let rfid = '';
      if (action.datas.cardNum) {
        rfid = this.appCard.transform(action.datas.cardNum);
      } else if (action.datas.RFID) {
        rfid = this.appCard.transform(action.datas.RFID);
      } else if (action.datas.idTag) {
        rfid = this.appCard.transform(action.datas.idTag);
      }
      if (rfid) {
        if (description) description += '. ';
        if (rfid !== 'App' && rfid !== 'FFFFFF') {
          description += `${this.translate.instant('RFID')}: ${rfid}`;
        }
      }
      if (action.datas.type) {
        if (description) description += '. ';
        description += `${this.translate.instant('ACTION_INFO_TYPE')}: ${
          action.datas.type
        }`;
      }
      if (action.datas.reason) {
        let reason = action.datas.reason;
        if (description) description += '. ';
        if (action.typeLog !== 'TRANSACTION_REQUEST') {
          description += `${this.translate.instant('ACTION_REASON')}: `;
        }

        if (action.typeLog === 'AUTOHEAL') {
          reason = this.translate.instant(AutohealReasonLabel[reason]);
        }

        if(reason === 'ROAMING_REMOTE_START') {
          reason = this.translate.instant('STARTED_WITH_ROAMING_EMSP')
        }
        if(reason === 'ROAMING_LOCAL_START') {
          reason = this.translate.instant('STARTED_WITH_ROAMING_TAG')
        }
        if(reason === 'PAYMENT_TERMINAL') {
          reason = this.translate.instant('PAYMENT_TERMINAL')
        }

        description += reason;
      }
      if (action.datas.StartMethod) {
        if (description) description += '. ';
        description += this.getStartedFrom(action.datas.StartMethod);
      }

      if (action.datas.status && boxAnswers.includes(action.typeLog)) {
        if (description) description += '. ';
        switch (action.datas.status.toUpperCase()) {
          case 'ACCEPTED':
            description += this.translate.instant('ACCEPTED_BY_BOX');
            break;
          case 'REJECTED':
            description += this.translate.instant('REJECTED_BY_BOX');
            break;
          case 'NOTSUPPORTED':
            description += `${this.translate.instant('BOX_ANSWER')}: ${action.datas.status}`;
            break;
          default:
            description += `${this.translate.instant(
              'BOX_ANSWER'
            )}: ${this.translate.instant(
              String(action.datas.status).toUpperCase()
            )}`;
            break;
        }
      }

      if(action.typeLog === 'START_TRANSACTION') {
        if(action?.datas?.triggerReason && action?.datas?.triggerReason !== 'Unknown') {
          description += `. Trigger reason: ${action.datas.triggerReason}`;
        }
      }
      if(action.typeLog === 'NEW_FIRMWARE' && action?.datas?.OldFW && action?.datas?.NewFW) {
        description = ` `
      }
    }

    if (!description) description = '-';

    return description;
  }
  transformStatus(action) {
    if (!action) return action;

    let status = '-';

    if (action.datas && action.datas.Status) {
      if (action.datas.Status.toUpperCase() === 'SUSPENDEDEV') {
        status = this.translate.instant('SUSPENDEDEV');
      } else if (action.datas.Status.toUpperCase() === 'SUSPENDEDEVSE') {
        status = this.translate.instant('SUSPENDEDEVSE');
      } else if (
        action.datas.Status.toUpperCase().indexOf('SUSPENDED') !== -1
      ) {
        status = this.translate.instant('PAUSED');
      } else {
        status = action.datas.Status;
      }
    }
    return status;
  }
  transformWarnings(action) {
    switch (action.errorCode) {
      case 'stopDateWasBeforeStartDate':
        return this.translate.instant('STOP_DATE_WAS_BEFORE_START_DATE');
      case 'zeroPricingBecauseTheMeterStopValuesSeemIncorrect':
        return this.translate.instant(
          'ZERO_PRICING_BECAUSE_METER_STOP_VALUES_INCORRECT'
        );
      case 'noOccupiedPriceForOfflineSessions':
        return this.translate.instant('NO_OCCUPIED_PRICE_FOR_OFFLINE_SESSIONS');
      case 'paymentNotApprovedOnThisBoxModel':
        return this.translate.instant('PAYMENT_NOT_APPROVED_ON_THIS_BOX_MODEL');
      case 'zeroPricingUnderFiveMinutes':
        return this.translate.instant('ZERO_PRICING_UNDER_FIVE_MINUTES');
      case 'sessionManuallyStoppedByAdmin':
        return this.translate.instant('SESSION_MANUALLY_STOPPED_BY_ADMIN');
      default:
        return action.errorCode;
    }
  }
  transformErrorCode(action) {
    let code = '';
    if (action.typeLog === 'TRANSACTION_WARNING') {
      code = this.transformWarnings(action);
    } else if (action.errorCode) {
      code = action.errorCode;

      if (
        ['AVAILABLE', 'FAULTED', 'UNAVAILABLE'].includes(code.toUpperCase())
      ) {
        code = '';
      } else if (code.toUpperCase() === 'CARDNOTVALID') {
        code = this.translate.instant('PAYMENT_CARD_NOT_VALID');
      } else if (code.toUpperCase() === 'OTHERERROR') {
        if (action.datas.VendorErrorCode) {
          code = `${this.translate.instant('BOX_ERROR')}: ${
            action.datas.VendorErrorCode
          }`;
        } else {
          code = this.translate.instant('UNKNOWN_ERROR');
        }
      } else if (code.toUpperCase() === 'ERRORINTHEBOX') {
        if (action.datas._Description) {
          code = action.datas._Description;
        }
      } else if (code.toUpperCase() === 'AUTHORIZATIONNEWCARDREGISTERED') {
        code = '';
      } else if (code.toUpperCase() !== 'NOERROR') {
        code = code.replace(/([A-Z])/g, ' $1').trim();
        code = code.toLowerCase();
        code = code.charAt(0).toUpperCase() + code.slice(1);
        if (action.datas.VendorErrorCode) {
          code += `. ${this.translate.instant('BOX_ERROR')}: ${
            action.datas.VendorErrorCode
          }`;
        }
      } else if (code.toUpperCase() === 'NOERROR') {
        code = '';
      }

      if (
        action.datas.ErrorCode &&
        action.datas.ErrorCode.toUpperCase() !== 'NOERROR'
      ) {
        if (code) code += '. ';
        if (
          action.datas.ErrorCode.toUpperCase() === 'OTHERERROR' &&
          action.ErrorName
        ) {
          code += action.ErrorName;
        } else {
          code += action.datas.ErrorCode;
        }
        if (action.datas.VendorErrorCode)
          code += ' (' + action.datas.VendorErrorCode + ')';
      }
    }

    return code;
  }
  checkError(action) {
    if (!action) return action;

    let error: any = false;

    if (
      action.typeLog &&
      isNaN(action.typeLog) &&
      action.typeLog.toUpperCase().indexOf('ERROR') !== -1
    ) {
      error = this.translate.instant('ERROR');
    }
    if (
      action.errorCode &&
      isNaN(action.errorCode) &&
      action.errorCode.toUpperCase() === 'FAULTED'
    ) {
      error = this.translate.instant('FAULTED');
    }
    if (
      action.datas &&
      action.datas.ErrorCode &&
      action.datas.ErrorCode.toUpperCase() === 'OTHERERROR'
    ) {
      error = this.translate.instant('ERROR');
    }
    if (
      action.datas &&
      action.datas.ErrorCode &&
      action.datas.ErrorCode.toUpperCase().indexOf('FAILURE') !== -1
    ) {
      error = this.translate.instant('FAILURE');
      ('');
    }
    //Default
    if (!error && error.IsError) {
      error = this.translate.instant('ERROR');
    }

    return error;
  }
}
