import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TableOptions, TableMessages } from 'src/app/classes/table-options';
import { UserType } from 'src/app/user-roles';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { JsonViewerComponent } from '../../json-viewer/json-viewer.component';
import { BootReasonType } from 'src/app/types/BootReasonType';
import { TranslateService } from '@ngx-translate/core';
import { sanitizeObject } from 'src/app/helpers/file';

@Component({
  selector: 'sc-action-history-table',
  templateUrl: './action-history-table.component.html',
  styleUrls: ['./action-history-table.component.scss']
})
export class ActionHistoryTableComponent implements OnInit {
  @Input() actions: any[] = [];
  @Input() tableOptions: TableOptions = new TableOptions();
  @Input() loading: boolean = true;
  @Input() refreshing: boolean = false;
  @Input() messages: TableMessages = new TableMessages();
  @Input() showPoint: boolean = true;
  @Input() showCustomer: boolean = true;

  @Output() route: EventEmitter<any> = new EventEmitter();
  @Output() page: EventEmitter<any> = new EventEmitter();

  userType = UserType;

  selectedAction: any = {};
  selectedChargingProfile: any = {};

  @Input() fixedHeight: string = null;
  @Input() scrollable: boolean = false;

  constructor(private dialog: MatDialog, private translate: TranslateService) {}

  ngOnInit() {
    this.initTableMessages();
  }

  initTableMessages() {
    const messages = new TableMessages();
    this.messages.totalMessage = this.translate.instant(messages.totalMessage);
    this.messages.emptyMessage = this.translate.instant(messages.emptyMessage);
    this.messages.errorMessage = this.translate.instant(messages.errorMessage);
  }

  selectAction(action) {
    this.selectedAction = action;
  }

  setProfile(action) {
    this.selectedChargingProfile = action.datas;
  }

  onRoute() {
    this.route.emit();
  }

  onPage(e) {
    this.tableOptions = Object.assign(this.tableOptions, e);

    this.loading = true;
    this.page.emit(e);
  }

  showAction(action) {
    if (!action) return;
    let dialogRef: MatDialogRef<JsonViewerComponent>;
    dialogRef = this.dialog.open(JsonViewerComponent);
    dialogRef.componentInstance.json = sanitizeObject(action);
    dialogRef.componentInstance.title = this.translate.instant(action.typeLog);
    dialogRef.componentInstance.date = action.UTC_log;
  }


  getReasonText(type: BootReasonType) {
    return (type && BootReasonType[type]) || null;
  }

  getRowClass(row: any): string {
    if (row?.IsError) return 'datatable-body-row--error datatable-body-row--bold';
    if(row?.IsWarning) return 'datatable-body-row--warning datatable-body-row--bold';
    switch (row.typeLog) {
      case 'AUTOHEAL':
        return 'datatable-body-row--success datatable-body-row--bold';
      case 'NEW_FIRMWARE':
        return 'datatable-body-row--important datatable-body-row--bold';
      default:
        return '';
    }
  }
}
