import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionHistoryTableComponent } from './action-history-table.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { PipesModule } from 'src/app/pipes/pipes.module';

import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { MatTooltipModule } from '@angular/material/tooltip';

import { JsonViewerModule } from '../../json-viewer/json-viewer.module';
import { LimitationPopoverModule } from '../../charging-limitations/limitation-popover/limitation-popover.module';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    NgxDatatableModule,
    NgbModule,
    DirectivesModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    JsonViewerModule,
    LimitationPopoverModule,
    RouterModule,
    MatTooltipModule,
    PipesModule
  ],
  declarations: [ActionHistoryTableComponent],
  exports: [ActionHistoryTableComponent]
})
export class ActionHistoryTableModule {}
