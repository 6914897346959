/* @angular */
import { BrowserModule, Title } from '@angular/platform-browser';
import {
  NgModule,
  APP_INITIALIZER,
  ENVIRONMENT_INITIALIZER,
  inject,
  ErrorHandler
} from '@angular/core';
import {
  HashLocationStrategy,
  LocationStrategy,
  registerLocaleData
} from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSliderModule } from '@angular/material/slider';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS } from '@angular/material/slide-toggle';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LetDirective, PushPipe } from '@ngrx/component';

import * as Sentry from '@sentry/angular';

import { QueryClientService } from '@ngneat/query';
import { environment } from '../environments/environment';

/* Defaults */
import { Defaults } from './defaults';

/* Routing */
import { routes } from './app.routing';

/* App */
import { AppComponent } from './app.component';

/* Modules */
import { AuthModule } from './services/auth/auth.module';
import { MainSidenavModule } from './components/main-sidenav/main-sidenav.module';
import { DialogModule } from './services/dialog/dialog.module';

/* Services */
import { Services } from './services/services';

/* External */
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Loader } from '@googlemaps/js-api-loader';

/* Locale */
import localeNB from '@angular/common/locales/nb';
import { JwtModule } from '@auth0/angular-jwt';
import { AddCompanyModule } from './components/admin/companies/add-company/add-company.module';
import { UserSearchModule } from './components/admin/users/user-search/user-search.module';
import {
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { StateModule } from './lib/state/state.module';
import { TraceService } from '@sentry/angular';
import { GlobalErrorHandler } from './error-handler';

import {
  Environment,
  EnvironmentService
} from '@smartcharge/shared/core/environment';
import {
  LocalStorageDriver,
  STORAGE,
  StorageService
} from '@smartcharge/shared/core/storage';
import { SharedModule } from '@smartcharge/shared/shared.module';
import { APP_CONFIG } from '@smartcharge/shared/core/config';
import { WhitelabelInstance } from './services/whitelabel/whitelabel-instance.service';
import { SingleSessionModule } from './components/single-session/single-session.module';

registerLocaleData(localeNB);

export function tokenGetter() {
  return localStorage.getItem('token');
}

function isDevelopmentEnvironment(): boolean {
  const { origin } = window.location;

  const isQa = origin.includes('qa');
  const isStaging = origin.includes('staging');
  const isSandbox =
    origin.includes('sandbox') || origin.includes('agreeable-bush-05b6fad03.4');

  return isQa || isStaging || isSandbox;
}

isDevelopmentEnvironment();

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    //Modules
    JwtModule.forRoot({
      config: {
        tokenGetter: () => {
          return localStorage.getItem('token');
        },
        allowedDomains: [
          'api.smartcharge.io',
          'app.smartcharge.io',
          'smartchargeapipd.azurewebsites.net',
          'meshcrafts-demo-api-app-service-stagging.azurewebsites.net',
          'meshcrafts-demo-api-app-service-qa.azurewebsites.net',
          'current-api-sandbox.azurewebsites.net',
          'smartchargebackends.azurewebsites.net',
          'smartchargebackendp.azurewebsites.net',
          'current-central-system-sandbox.azurewebsites.net'
        ],
        headerName: 'Authorization',
        throwNoTokenError: false,
        authScheme: 'Bearer '
      }
    }),
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    }),
    AuthModule,
    NgbModule,
    MainSidenavModule,
    DialogModule,
    UserSearchModule,
    AddCompanyModule,
    MatMomentDateModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    StateModule,
    MatSnackBarModule,
    MatSliderModule,
    MatIconModule,
    LetDirective,
    PushPipe,
    SharedModule,
    SingleSessionModule
  ],
  providers: [
    //Services
    Services,
    {
      provide: STORAGE,
      useClass: LocalStorageDriver
    },
    {
      provide: APP_INITIALIZER,
      useFactory:
        (
          environmentService: EnvironmentService,
          storageService: StorageService,
          whiteLabelInstance: WhitelabelInstance
        ) =>
        async () => {
          let environment = await storageService.get('environment');
          if (!environment) {
            const { hostname } = window.location;
            if (hostname.startsWith('qa')) {
              environment = Environment.QA;
            } else if (hostname.startsWith('sandbox')) {
              environment = Environment.Sandbox;
            } else if (
              hostname.startsWith('staging') ||
              hostname.startsWith('localhost')
            ) {
              environment = Environment.Staging;
            }
            await environmentService.init(environment as Environment);
          }
          await whiteLabelInstance.init();
        },
      deps: [EnvironmentService, StorageService, WhitelabelInstance],
      multi: true
    },
    {
      provide: Loader,
      useValue: new Loader({
        apiKey: 'AIzaSyCnZpUSVr-upxo98f077J3q3cq6WrIxTMs',
        libraries: ['places'],
        language: localStorage.getItem('lang') || navigator.language
      })
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true
    },
    {
      provide: APP_CONFIG,
      useFactory: (whiteLabelInstance: WhitelabelInstance) =>
        whiteLabelInstance.getConfig(),
      deps: [WhitelabelInstance]
    },
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'primary' }
    },
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: { color: 'primary' }
    },
    {
      provide: MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS,
      useValue: { color: 'primary' }
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: isDevelopmentEnvironment()
      })
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    Title,
    Defaults,
    // { provide: LOCALE_ID, useValue: 'nb'},
    { provide: MAT_DATE_LOCALE, useValue: 'nb' },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    /*{
            provide: HTTP_INTERCEPTORS,
            useClass: NoCacheHttpService,
            multi: true
        },*/
    MatIconRegistry,
    environment.production
      ? []
      : {
          provide: ENVIRONMENT_INITIALIZER,
          multi: true,
          useValue() {
            const queryClient = inject(QueryClientService);
            import('@ngneat/query-devtools').then((m) => {
              m.ngQueryDevtools({ queryClient });
            });
          }
        },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {
  constructor(public matIconRegistry: MatIconRegistry) {
    matIconRegistry.setDefaultFontSetClass('material-icons-outlined');
    // matIconRegistry.registerFontClassAlias('material-icons-outlined', 'material-icons-rounded-outlined');
    // matIconRegistry.registerFontClassAlias('material-icons-round', 'material-icons-rounded-round');
  }
}
