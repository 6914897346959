import { Defaults } from 'src/app/defaults';

const defaults = new Defaults();
export const SpotPriceChartConfig = {
  spotPriceLineConfig: {
    label: 'Spot price /kWh',
    data: [],
    backgroundColor: '#F47E6A',
    borderColor: '#F47E6A',
    tension: 0.4,
    parsing: {
      xAxisKey: 'hour',
      yAxisKey: 'price.spotPrice'
    }
  },
  chargingPriceLineConfig: {
    label: 'Charging price /kWh',
    data: [],
    backgroundColor: defaults.colors.primary,
    borderColor: defaults.colors.primary,
    tension: 0.4,
    parsing: {
      xAxisKey: 'hour',
      yAxisKey: 'price.charginPrice'
    }
  }
};
