import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'sc-limitation-popover',
  templateUrl: './limitation-popover.component.html',
  styleUrls: ['./limitation-popover.component.scss']
})
export class LimitationPopoverComponent implements OnInit {
  @Input('selected') selectedChargingProfile: any = {};
  @Input('title') title: string = '';
  @Input('value') value: string = '';
  @Input('customMessage') customMessage: string = null;

  constructor() {}

  ngOnInit() {
    if (this.selectedChargingProfile.new_amps_limit)
      this.selectedChargingProfile.amps_limit =
        this.selectedChargingProfile.new_amps_limit;
  }

  isNum(amp): boolean {
    return !isNaN(amp);
  }

  cleanStringLimitation(code) {
    let newString = code
      .match(/[A-Z][a-z]+|[0-9]+/g)
      .join('_')
      .toUpperCase();

    return newString.charAt(0).toUpperCase() + newString.slice(1);
  }
}
