<ngx-datatable
  [columnMode]="'force'"
  [footerHeight]="50"
  [rowHeight]="'auto'"
  [scrollbarV]="scrollable"
  [rows]="actions"
  [limit]="tableOptions.limit"
  [count]="tableOptions.total"
  [offset]="tableOptions.offset"
  [externalPaging]="true"
  class="material no-click"
  [virtualization]="!scrollable"
  [loadingIndicator]="loading"
  [messages]="messages"
  [ngStyle]="fixedHeight ? { height: fixedHeight } : {}"
  [rowClass]="getRowClass"
  (page)="onPage($event)">
  <ngx-datatable-column
    prop="ActionTypeName"
    name="{{ 'ACTION_TYPE' | translate }}"
    [sortable]="false">
    <ng-template let-action="row" ngx-datatable-cell-template>
      <span
        class="icon-inline action-icon"
        [ngClass]="{
          'txt-danger': action.IsError,
          'txt-orange': action.IsWarning && !action.IsError
        }">
        <mat-icon *ngIf="action.typeLog" class="mr-1">
          {{ action | action: 'icon' }}
        </mat-icon>
        <span>
          {{ action | action: 'type' }}
        </span>
      </span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="{{ 'EXTRA' | translate }}" [sortable]="false">
    <ng-template let-action="row" ngx-datatable-cell-template>
      <sc-limitation-popover
        *ngIf="action.typeLog === 'NEW_CHARGING_PROFILE'"
        [value]="action | action: 'extra'"
        title="{{ 'NEW_CHARGING_PROFILE' | translate }}"
        [selected]="action.datas"></sc-limitation-popover>
      <span *ngIf="action.typeLog !== 'NEW_CHARGING_PROFILE'">
        <span
          *ngIf="
            action.ErrorName &&
            (action.ErrorDescription ||
              action.ActionAdvised ||
              action.DescriptionDetails)
          "
          triggers="mouseenter:mouseleave"
          (mouseover)="selectAction(action)"
          class="hover-text"
          placement="auto"
          container="body"
          [ngbPopover]="errorMessagePop">
          {{ action | action: 'extra' }}
        </span>
        <span class="icon-inline action-icon">
          <span
            *ngIf="
              (action.typeLog === 'TRANSACTION_REQUEST' ||
                action.typeLog === 'START_TRANSACTION') &&
              action.datas &&
              (action.datas.StartMethod || action.datas.reason)
            ">
            <!-- @TODO: I saw this in another table. Could be compontentized-->
            <span
              [ngSwitch]="action.datas.StartMethod || action.datas.reason"
              class="start-method">
              <mat-icon
                *ngSwitchCase="'SMS'"
                matTooltip="{{ 'STARTED_WITH_SMS' | translate }}">
                sms
              </mat-icon>
              <mat-icon
                *ngSwitchCase="'RFID'"
                matTooltip="{{ 'STARTED_WITH_RFID' | translate }}">
                settings_remote
              </mat-icon>
              <mat-icon
                *ngSwitchCase="'QR'"
                matTooltip="{{ 'STARTED_WITH_QR' | translate }}">
                border_outer
              </mat-icon>
              <mat-icon
                *ngSwitchCase="'App'"
                matTooltip="{{ 'STARTED_WITH_APP' | translate }}">
                phone_iphone
              </mat-icon>
              <mat-icon
                *ngSwitchCase="'Dashboard'"
                matTooltip="{{ 'STARTED_FROM_DASHBOARD' | translate }}">
                tv
              </mat-icon>
              <mat-icon
                *ngSwitchCase="'PLUG_AND_CHARGE'"
                matTooltip="{{ 'STARTED_AUTOMATICALLY' | translate }}">
                power
              </mat-icon>
              <mat-icon
                *ngSwitchCase="'ROAMING_REMOTE_START'"
                matTooltip="{{ 'STARTED_WITH_ROAMING_EMSP' | translate }}">
                phone_iphone
              </mat-icon>
              <mat-icon
                *ngSwitchCase="'ROAMING_LOCAL_START'"
                matTooltip="{{ 'STARTED_WITH_ROAMING_TAG' | translate }}">
                settings_remote
              </mat-icon>
              <mat-icon
                *ngSwitchCase="'PAYMENT_TERMINAL'"
                matTooltip="{{ 'PAYMENT_TERMINAL' | translate }}">
                point_of_sale
              </mat-icon>
              <mat-icon
                *ngSwitchDefault
                matTooltip="{{ 'UNKNOWN_START_METHOD' | translate }}">
                power
              </mat-icon>
            </span>
          </span>
          <span *ngIf="!action.ErrorName && !action.ErrorDescription">
            {{ action | action: 'extra' }}
            @if (
              action.typeLog === 'AUTOHEAL' &&
              action?.datas?.reason === 'AutohealSetDefaultConfig' &&
              action.datas?.keysChanged?.length
            ) {
              <p>
                {{
                  'WRONG_CONFIGURATIONS_LIST'
                    | translate
                      : { configList: action.datas.keysChanged.join(',') }
                }}
              </p>
            }
            <span *ngIf="action.typeLog === 'BOOT_NOTIFICATION'">
              <button
                *ngIf="getReasonText(action.datas.reason)"
                mat-icon-button
                matSuffix
                type="button"
                matTooltip="{{
                  getReasonText(action.datas.reason) | translate
                }}">
                <mat-icon>help_outline</mat-icon>
              </button>
            </span>
            @if (
              action.typeLog === 'NEW_FIRMWARE' &&
              action?.datas?.OldFW &&
              action?.datas?.NewFW
            ) {
              <span>{{ action.datas.OldFW }}</span>
              <mat-icon class="mx-1">east</mat-icon>
              <span>{{ action.datas.NewFW }}</span>
            }
          </span>
        </span>
      </span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    *ngIf="showPoint"
    prop="ChargingPointName"
    name="{{ 'POINT' | translate }}"
    [sortable]="false">
    <ng-template let-action="row" ngx-datatable-cell-template>
      <a
        *ngIf="action.FK_ChargePointID"
        (click)="onRoute()"
        routerLink="/station/{{ action.FK_ChargingStationID }}/point/{{
          action.FK_ChargePointID
        }}/sessions">
        {{ action.ChargingPointName }}
      </a>
      <span *ngIf="!action.FK_ChargePointID && action.ChargingPointName">
        {{ 'A_POINT_IN_BOX' | translate }} {{ action.ChargingPointName }}
      </span>
      <span *ngIf="!action.FK_ChargePointID && !action.ChargingPointName">
        -
      </span>
    </ng-template>
  </ngx-datatable-column>
  <ng-container *ngIf="showCustomer">
    <ngx-datatable-column
      *canSee="userType.Admin"
      [maxWidth]="120"
      name="{{ 'CUSTOMER' | translate }}"
      [sortable]="false">
      <ng-template let-action="row" ngx-datatable-cell-template>
        <a
          *ngIf="action.FK_CustomerID"
          (click)="onRoute()"
          routerLink="/users/{{ action.FK_CustomerID }}">
          {{ action.FK_CustomerID }}
        </a>
        <span *ngIf="!action.FK_CustomerID">-</span>
      </ng-template>
    </ngx-datatable-column>
  </ng-container>

  <ngx-datatable-column
    prop="UTC_log"
    name="{{ 'DATE' | translate }}"
    [maxWidth]="150"
    [sortable]="false">
    <ng-template let-action="row" ngx-datatable-cell-template>
      {{ action.UTC_log | cDate: 'L LT' }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    name=""
    [maxWidth]="100"
    [sortable]="false"
    *canSee="userType.Admin">
    <ng-template let-action="row" ngx-datatable-cell-template>
      <button
        mat-icon-button
        matTooltip="{{ 'DETAILS' | translate }}"
        class="small-button"
        (click)="showAction(action)">
        <mat-icon>list_alt</mat-icon>
      </button>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
<ng-template #errorMessagePop>
  <mat-list class="how-to">
    <mat-list-item class="list-table">
      <h3 matListItemTitle>
        <b>{{ selectedAction.ErrorName }}</b>
      </h3>
      <p matListItemLine>
        <span>{{ 'DESCRIPTION' | translate }}</span>
        <span>
          {{ selectedAction.ErrorDescription }}
          {{ selectedAction.DescriptionDetails }}
        </span>
      </p>
      <p matListItemLine>
        <span>{{ 'ACTION_ADVISED' | translate }}</span>
        <span>{{ selectedAction.ActionAdvised || '-' }}</span>
      </p>
      <p
        matListItemLine
        *ngIf="selectedAction.datas && selectedAction.datas.VendorErrorCode">
        <span>{{ 'VENDOR_ERROR_CODE' | translate }}</span>
        <span>{{ selectedAction.datas.VendorErrorCode || '-' }}</span>
      </p>
    </mat-list-item>
  </mat-list>
</ng-template>
