import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Chart, ChartConfiguration } from 'chart.js';
import moment, { Moment } from 'moment';
import { SpotPriceService } from 'src/app/services/spot-price/spot-price.service';
import { SpotPriceChartConfig } from '../spot-price-chart-config';
import { SpotPriceItemWithFee } from '../types';

@Component({
  selector: 'sc-historical-spot-price',
  templateUrl: './historical-spot-price.component.html',
  styleUrls: ['./historical-spot-price.component.scss']
})
export class HistoricalSpotPriceComponent implements OnInit {
  stationID: number;
  startDate: Moment = moment();
  isLoading: boolean = true;
  priceData: SpotPriceItemWithFee[] = [];
  @ViewChild('priceChart') chartSpotPrice: ElementRef<HTMLCanvasElement>;
  priceChart: any;

  constructor(private spotPriceService: SpotPriceService) {}
  ngOnInit() {
    if (this.stationID) {
      this.getHistoricalSpotPrice();
    }
  }

  getHistoricalSpotPrice = () => {
    this.isLoading = true;
    const date = this.startDate.clone();
    const start = date.startOf('day').valueOf();
    const end = date.add(1, 'day').startOf('day').valueOf();

    this.spotPriceService.getSpotPrice(this.stationID, start, end).subscribe({
      next: (res) => {
        this.isLoading = false;
        this.priceData = [...res];
        this.drawChart();
      },
      error: (err) => {
        this.isLoading = false;
        console.log(err);
      }
    });
  };

  changeDay = (day: string) => {
    if (this.stationID) {
      switch (day) {
        case 'previous':
          this.startDate = this.startDate.subtract(1, 'day');
          break;
        case 'next':
          this.startDate = this.startDate.add(1, 'day');
          break;
        default:
          this.startDate = moment();
      }
      this.getHistoricalSpotPrice();
    }
  };

  drawChart() {
    const ctx = this.chartSpotPrice.nativeElement.getContext('2d') || null;
    if (!ctx) return;
    const data = {
      datasets: [
        {
          ...SpotPriceChartConfig.spotPriceLineConfig,
          data: this.priceData as any
        }
      ]
    };

    const config: ChartConfiguration = {
      type: 'line',
      data,
      options: {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
          y: {
            beginAtZero: true,
            grid: {
              display: false,
              drawBorder: false
            }
          },
          x: {
            grid: {
              display: false,
              drawBorder: false
            }
          }
        },
        plugins: {
          legend: {
            display: false
          }
        },
        elements: {
          point: {
            radius: (ctx) => {
              const hourIndex = new Date().getHours();
              const selectedDate = this.startDate
                .clone()
                .toDate()
                .setHours(0, 0, 0, 0);
              const currentDate = new Date().setHours(0, 0, 0, 0);
              return ctx.dataIndex === hourIndex && selectedDate === currentDate
                ? 3
                : 2;
            }
          }
        }
      },
      plugins: [
        {
          id: 'lineOfCurrentHour',
          afterDatasetDraw: (chart) => {
            const selectedDate = this.startDate
              .clone()
              .toDate()
              .setHours(0, 0, 0, 0);
            const currentDate = new Date().setHours(0, 0, 0, 0);
            if (selectedDate === currentDate) {
              const hourIndex = new Date().getHours();
              const pointElement = chart.getDatasetMeta(0).data[hourIndex];
              const scale = chart.scales.y;
              chart.ctx.beginPath();
              chart.ctx.moveTo(pointElement.x, scale.top);
              chart.ctx.setLineDash([10, 10]);
              chart.ctx.strokeStyle = '#918d8d';
              chart.ctx.lineWidth = 2;
              chart.ctx.lineTo(pointElement.x, scale.bottom);
              chart.ctx.stroke();
            }
          }
        }
      ]
    };

    if (this.priceChart) {
      this.priceChart.data.datasets = data.datasets;
      this.priceChart.update();
    } else {
      this.priceChart = new Chart(ctx, config);
    }
  }
}
